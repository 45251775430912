import React from "react";
import { AutocompleteArrayInput, AutocompleteInput, DateInput, DateTimeInput, FormDataConsumer, FormWithRedirect, GET_LIST, NumberInput, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useFormContext } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useOptions } from "../../shared/hooks/useOptions";
import { getUser } from "../../authProvider";

const useStyles = makeStyles({
  root: {
    // set margin to nested grid
    "& .MuiGrid-root": {
      padding: "4px",
    },
  }
});

export const WorkPermitForm = (props: FormProps) => {
  const user = getUser();
  const classes = useStyles();


  return (
    user ?
      <FormWithRedirect
        {...props}
        render={(formProps: any) => (
          <form>
            <Grid container classes={classes} xs={12} style={{ padding: "1em" }} >
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  {...initInput("employee", props.fieldOptions, props.canEdit)}
                  reference="autocomplete/employee"
                  source="employee"
                  defaultValue={user.employee}
                  disabled={
                    user.permissions.includes("base.change_change_workpermit_c1") ||
                      user.permissions.includes("base.change_change_workpermit_c2") ||
                      user.permissions.includes("base.change_change_workpermit_c3") ? false : true
                  }
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={12} md={6}>
                <ReferenceInput
                  {...initInput("work_permit_sub_type", props.fieldOptions, props.canEdit)}
                  reference="autocomplete/workpermitsubtype"
                  source="work_permit_sub_type"
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={12} md={6}>
                <DateTimeInput {...initInput("date_in", props.fieldOptions, props.canEdit)}
                  onChange={(e) => {
                    if (formProps.form.getFieldState("unit").unit === "D") {
                      formProps.form.change("date_in", new Date(e.target.valueAsDate.setHours(0, 0, 0, 0)));
                    }
                  }} />
              </Grid>

              <Grid item xs={12} md={3}>
                <SelectInput
                  {...initInput("unit", props.fieldOptions, props.canEdit, "array")}
                  choices={props.fieldOptions.unit.choices}
                  defaultValue={"H"}
                  onChange={(e) => {
                    if (formProps.form.getFieldState("date_in").value && formProps.form.getFieldState("date_in").value.setHours) {
                      if (e.target.value === "D") {
                        formProps.form.change("date_in", new Date(formProps.form.getFieldState("date_in").value.setHours(0, 0, 0, 0)));
                      } else {
                        formProps.form.change("date_in", new Date(formProps.form.getFieldState("date_in").value));
                      }
                    }

                  }} />
              </Grid>

              <Grid item xs={12} md={3}>
                {
                  formProps.form.getFieldState("time_request") &&
                  <FormDataConsumer>
                    {({ formData }) => (
                      formData.unit === "H" ?
                        <SelectInput source={"select_time_request_hours"} choices={[
                          { id: 0.25, name: "0:15" },
                          { id: 0.5, name: "0:30" },
                          { id: 0.75, name: "0:45" },
                          { id: 1, name: "1:00" },
                          { id: 1.25, name: "1:15" },
                          { id: 1.5, name: "1:30" },
                          { id: 1.75, name: "1:45" },
                          { id: 2, name: "2:00" },
                          { id: 2.25, name: "2:15" },
                          { id: 2.5, name: "2:30" },
                          { id: 2.75, name: "2:45" },
                          { id: 3, name: "3:00" },
                          { id: 3.25, name: "3:15" },
                          { id: 3.5, name: "3:30" },
                          { id: 3.75, name: "3:45" },
                          { id: 4, name: "4:00" },
                          { id: 4.25, name: "4:15" },
                          { id: 4.5, name: "4:30" },
                          { id: 4.75, name: "4:45" },
                          { id: 5, name: "5:00" },
                          { id: 5.25, name: "5:15" },
                          { id: 5.5, name: "5:30" },
                          { id: 5.75, name: "5:45" },
                          { id: 6, name: "6:00" },
                          { id: 6.25, name: "6:15" },
                          { id: 6.5, name: "6:30" },
                          { id: 6.75, name: "6:45" },
                          { id: 7, name: "7:00" },
                          { id: 7.25, name: "7:15" },
                          { id: 7.5, name: "7:30" },
                          { id: 7.75, name: "7:45" }
                        ]} label={"Seleziona ore"} fullWidth
                          onChange={(e) => {
                            formProps.form.change("time_request", parseFloat(e.target.value) * 60);
                          }}
                          defaultValue={formProps.form.getFieldState("time_request").value / 60}
                        /> :
                        <NumberInput {...initInput("select_time_request_days", props.fieldOptions, props.canEdit)}
                          step={1} min={0} max={31}
                          label={"Seleziona giorni"} fullWidth
                          onChange={(e) => {
                            formProps.form.change("time_request", e.target.value);
                          }}
                          defaultValue={formProps.form.getFieldState("time_request").value}
                        />
                    )}
                  </FormDataConsumer>
                }
              </Grid>

              <Grid item xs={12} alignContent="center" style={{ textAlign: "center" }}>
                <Typography variant="h6">Data fine :</Typography>
                {
                  formProps.form.getFieldState("date_in") &&
                  formProps.form.getFieldState("time_request") &&
                  <FormDataConsumer>
                    {({ formData }) => (
                      <Typography variant="h6">{
                        formProps && formProps.form && formProps.form.getFieldState("date_in") &&
                          formProps.form.getFieldState("date_in").value &&
                          formProps.form.getFieldState("date_in").value.getTime &&
                          formProps.form.getFieldState("time_request") &&
                          formProps.form.getFieldState("time_request").value ? (
                          new Date(formProps.form.getFieldState("date_in").value.getTime() +
                            (formData.unit === "D" ?
                              formProps.form.getFieldState("time_request").value * 60000 * 60 * 24 :
                              formProps.form.getFieldState("time_request").value * 60000)).toLocaleString('it-IT')
                        ) : ""
                      }</Typography>
                    )}
                  </FormDataConsumer>
                }
              </Grid>

              <Grid item xs={12} style={{ display: "none" }}>
                <NumberInput {...initInput("time_request", props.fieldOptions, props.canEdit)}
                  step={0.25}
                />
              </Grid>

              <TextInput {...initInput("notes_employee", props.fieldOptions, props.canEdit)} />

              <TextInput {...initInput("info", props.fieldOptions, props.canEdit)} />
              <TextInput {...initInput("notes_out", props.fieldOptions, props.canEdit)} />
              <TextInput {...initInput("description", props.fieldOptions, props.canEdit)} />

              <Grid container style={{
                width: "100%",
                display: user.permissions.includes("base.change_change_workpermit_c1") ||
                  user.permissions.includes("base.change_change_workpermit_c2") ||
                  user.permissions.includes("base.change_change_workpermit_c3") ? "" : "none"
              }}>
                <Grid item xs={4}>
                  <SelectInput
                    {...initInput("check_1", props.fieldOptions, props.canEdit, "array")}
                    choices={props.fieldOptions.check_1.choices}
                    defaultValue={"W"}
                    disabled={user.permissions.includes("base.change_change_workpermit_c1") ? false : true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectInput
                    {...initInput("check_2", props.fieldOptions, props.canEdit, "array")}
                    choices={props.fieldOptions.check_2.choices}
                    defaultValue={"W"}
                    disabled={user.permissions.includes("base.change_change_workpermit_c2") ? false : true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectInput
                    {...initInput("check_3", props.fieldOptions, props.canEdit, "array")}
                    choices={props.fieldOptions.check_3.choices}
                    defaultValue={"W"}
                    disabled={user.permissions.includes("base.change_change_workpermit_c3") ? false : true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <CustomToolbar
              canDelete={getUser().is_admin === true ? props.canDelete : false}
              canEdit={props.canEdit}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        )} /> : null
  );
};
