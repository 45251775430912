import { Box, Button, Dialog, DialogTitle, Grid } from "@material-ui/core";
import { useMemo, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  ReferenceArrayInput,
  fetchEnd,
  fetchStart,
  required,
  useNotify,
  useRefresh,
} from "react-admin";
import { useDispatch } from "react-redux";
import { client } from "../../dataProvider";
import { Route, Segment } from "../types";
import { Form } from "react-final-form";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { ReferenceInputVehicle } from "../../components/reference-inputs/ReferenceInputVehicle";
import { ReferenceInputDriver } from "../../components/reference-inputs/ReferenceInputDriver";
import { OnChange } from "react-final-form-listeners";

interface SplitSegmentsDialogProps {
  onClose: () => void;
  open: boolean;
  segments: Segment[];
  onActiveRouteChange: (
    editMode: boolean,
    selectedRoute: Route | undefined
  ) => void;
}

export const SplitSegmentsDialog = (props: SplitSegmentsDialogProps) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const [loading, setLoading] = useState(false);

  const onChangeHandlerCarrier1 = (value: boolean, formProps: any) => {
    if (value) {
      formProps.change("vehicle_1", null);
      formProps.change("driver_1", null);
    } else {
      formProps.change("carrier_1", null);
    }
  };
  const onChangeHandlerCarrier2 = (value: boolean, formProps: any) => {
    if (value) {
      formProps.change("vehicle_2", null);
      formProps.change("driver_2", null);
    } else {
      formProps.change("carrier_2", null);
    }
  };

  const validate_1 = (value: any, allValues: any) => {
    if (allValues.vehicle_1 && !allValues.driver_1) return "Inserisci Autista";

    if (!allValues.vehicle_1 && allValues.driver_1) return "Inserisci Veicolo";

    if (allValues.vehicle_1 && allValues.driver_1 && allValues.carrier_1)
      return "Rimuovi autista-veicolo o corriere";

    if ((allValues.vehicle_1 && allValues.driver_1) || allValues.carrier_1) {
      if (!allValues.start_1) return "Inserisci Partenza";
      if (!allValues.end_1) return "Inserisci Arrivo";
      if (!allValues.dateStart_1) return "Inserisci Data";
    }
    if (!allValues.tags_1 || allValues.tags_1.length === 0) {
      return "Inserisci almeno un tag"
    }
    return undefined;
  };

  const validate_2 = (value: any, allValues: any) => {
    if (allValues.vehicle_2 && !allValues.driver_2) return "Inserisci Autista";

    if (!allValues.vehicle_2 && allValues.driver_2) return "Inserisci Veicolo";

    if (allValues.vehicle_2 && allValues.driver_2 && allValues.carrier_2)
      return "Rimuovi autista-veicolo o corriere";

    if ((allValues.vehicle_2 && allValues.driver_2) || allValues.carrier_2) {
      if (!allValues.start_2) return "Inserisci Partenza";
      if (!allValues.end_2) return "Inserisci Arrivo";
      if (!allValues.dateStart_2) return "Inserisci Data";
      if (
        allValues.dateStart_1 &&
        allValues.dateStart_1 >= allValues.dateStart_2
      )
        return "La data dev'essere maggiore di quella della prima tratta";
      if (!allValues.tags_2 || allValues.tags_2.length === 0) {
        return "Inserisci almeno un tag"
      }
    }
    return undefined;
  };

  const splitSegments = (values: any) => {
    const data = {
      splitPoint: values.splitPointId,

      toBeTractioned: values.toBeTractioned,
      segments: props.segments,

      plan_1: values.plan_1,
      dateStart_1: values.dateStart_1,
      start_1: values.start_1,
      end_1: values.end_1,
      vehicle_1: values.vehicle_1,
      driver_1: values.driver_1,
      carrier_1: values.carrier_1,
      tags_1: values.tags_1,

      plan_2: values.plan_2,
      dateStart_2: values.dateStart_2,
      start_2: values.start_2,
      end_2: values.end_2,
      vehicle_2: values.vehicle_2,
      driver_2: values.driver_2,
      carrier_2: values.carrier_2,
      tags_2: values.tags_2,

    };

    dispatch(fetchStart());
    client("segments/split_segments", { method: "POST", data })
      .then((data) => {
        if (data) {
          if (data.status && data.status === "error") {
            notify(data.detail, "warning")
          }
          else {
            notify("Tratte divise", "info");
            refresh();
          }
        }
      })
      .catch(() => notify("Errore nel dividere le tratte!", "warning"))
      .finally(() => {
        dispatch(fetchEnd());
        props.onClose();
        refresh();
        props.onActiveRouteChange(false, undefined);
        setLoading(false)
      });
  };
  const initialDate = useMemo(() => new Date(), []);
  return (
    <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth="xl">
      <DialogTitle>Spezza tratte utilizzando il seguente punto:</DialogTitle>
      <Form
        initialValues={{
          splitPointId: null,

          toBeTractioned: null,

          plan_1: false,
          dateStart_1: initialDate,
          handler_carrier_1: false,
          vehicle_1: null,
          carrier_1: null,
          driver_1: null,
          start_1: null,
          end_1: null,
          tags_1: null,

          plan_2: false,
          dateStart_2: initialDate,
          handler_carrier_2: false,
          vehicle_2: null,
          carrier_2: null,
          driver_2: null,
          start_2: null,
          end_2: null,
          tags_2: null,
        }}
        onSubmit={(values) => {
          setLoading(true)
          splitSegments(values);
        }}
        render={({ handleSubmit, form, values }) => (
          <>
            <OnChange name="handler_carrier_1">
              {(value, previous) => {
                onChangeHandlerCarrier1(value, form);
              }}
            </OnChange>
            <OnChange name="handler_carrier_2">
              {(value, previous) => {
                onChangeHandlerCarrier2(value, form);
              }}
            </OnChange>

            <Box p={2}>
              <Grid container>
                <Grid item xs={4}>
                  <h3>Prima tratta</h3>
                  <BooleanInput source="plan_1" label="Gestione prima tratta" />
                  {values.plan_1 ? (
                    <>
                      <DateTimeInput
                        source="dateStart_1"
                        label="Data partenza"
                        validate={[validate_1]}
                      />
                      <Grid container spacing={2} justify="center">
                        <Grid item xs={5}>
                          <ReferenceInputSubject
                            fullWidth
                            source="start_1"
                            label="Partenza"
                            validate={[validate_1]}
                            filter={{ type: "planner_receiver" }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <ReferenceInputSubject
                            fullWidth
                            source="end_1"
                            label="Arrivo"
                            validate={[validate_1]}
                            filter={{ type: "planner_receiver" }}
                          />
                        </Grid>
                      </Grid>
                      <BooleanInput
                        source="handler_carrier_1"
                        label="Vettore"
                      />
                      {values.handler_carrier_1 ? (
                        <ReferenceInputSubject
                          fullWidth
                          source="carrier_1"
                          filter={{ type: "carrier" }}
                          label="Corriere"
                          validate={[validate_1]}
                        />
                      ) : (
                        <>
                          <ReferenceInputVehicle
                            fullWidth
                            source="vehicle_1"
                            label="Veicolo"
                            validate={[validate_1]}
                          />
                          <ReferenceInputDriver
                            fullWidth
                            source="driver_1"
                            label="Autista"
                            validate={[validate_1]}
                          />
                        </>
                      )}
                      <ReferenceArrayInput
                        reference="autocomplete/tag"
                        defaultValue={[]}
                        source="tags_1"
                        isRequired={true}
                        label="Tag"
                        sort={{ "field": "label", "order": "ASC" }}
                        className="filter-field-small"
                      >
                        <AutocompleteArrayInput optionText="label" fullWidth />
                      </ReferenceArrayInput>
                    </>
                  ) : (
                    <Grid item xs={4}></Grid>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <h3>Punto intermedio</h3>
                  <ReferenceInputSubject
                    fullWidth
                    source="splitPointId"
                    label="Punto intermedio"
                    validate={[required()]}
                    filter={{ type: "planner_receiver" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h3>Seconda tratta</h3>
                  <BooleanInput
                    source="plan_2"
                    label="Gestione seconda tratta"
                  />
                  {values.plan_2 ? (
                    <>
                      <DateTimeInput
                        source="dateStart_2"
                        label="Data partenza"
                        validate={[validate_2]}
                      />
                      <Grid container spacing={2} justify="center">
                        <Grid item xs={5}>
                          <ReferenceInputSubject
                            fullWidth
                            source="start_2"
                            label="Partenza"
                            validate={[validate_2]}
                            filter={{ type: "planner_receiver" }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <ReferenceInputSubject
                            fullWidth
                            source="end_2"
                            label="Arrivo"
                            validate={[validate_2]}
                            filter={{ type: "planner_receiver" }}
                          />
                        </Grid>
                      </Grid>
                      <BooleanInput
                        source="handler_carrier_2"
                        label="Vettore"
                      />
                      {values.handler_carrier_2 ? (
                        <ReferenceInputSubject
                          fullWidth
                          source="carrier_2"
                          filter={{ type: "carrier" }}
                          label="Corriere"
                          validate={[validate_2]}
                        />
                      ) : (
                        <>
                          <ReferenceInputVehicle
                            fullWidth
                            source="vehicle_2"
                            label="Veicolo"
                            validate={[validate_2]}
                          />
                          <ReferenceInputDriver
                            fullWidth
                            source="driver_2"
                            label="Autista"
                            validate={[validate_2]}
                          />
                        </>
                      )}
                      <ReferenceArrayInput
                        reference="autocomplete/tag"
                        defaultValue={[]}
                        source="tags_2"
                        isRequired={true}
                        label="Tag"
                        sort={{ "field": "label", "order": "ASC" }}
                        className="filter-field-small">
                        <AutocompleteArrayInput optionText="label" fullWidth />
                      </ReferenceArrayInput>
                    </>
                  ) : (
                    <Grid item xs={4}></Grid>
                  )}
                </Grid>
                <Grid item justify="flex-end" xs={4}>
                  <BooleanInput
                    source="toBeTractioned"
                    label="Traziona"
                  ></BooleanInput>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Button
                    disabled={
                      loading ||
                        form.getState().values.splitPointId ? false : true
                    }
                    onClick={handleSubmit}
                  >
                    Spezza tratte
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      />
    </Dialog>
  );
};
