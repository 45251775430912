import { CustomList } from "../components/CustomList";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../shared/utils";

import { Tabs, Divider, Tab } from "@material-ui/core";
import { useState, useEffect, useCallback, Fragment } from "react";
import {
  Datagrid,
  TextField,
  ListProps,
  TextInput,
  DatagridProps,
  Identifier,
  ListContextProvider,
  useListContext,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FunctionField,
} from "react-admin";
import { IdentifierField } from "../components/IdentifierField";
import { getUser } from "../authProvider";

const tabs = [
  { id: null, name: "Tutti" },
  { id: "Autista", name: "Autisti" },
  { id: "Ufficio", name: "Ufficio" },
  { id: "Magazzino", name: "Magazzino" },
  { id: "Manutenzione", name: "Manutenzione" },
  { id: "Smistamento", name: "Smistamento" },
];

const TabbedDatagrid = (props: DatagridProps) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [all, setAll] = useState<Identifier[]>([] as Identifier[]);
  const [drivers, setDrivers] = useState<Identifier[]>([] as Identifier[]);
  const [office, setOffice] = useState<Identifier[]>([] as Identifier[]);
  const [mainteneance, setMaintenance] = useState<Identifier[]>(
    [] as Identifier[]
  );
  const [store, setStore] = useState<Identifier[]>([] as Identifier[]);
  const [sorting, setSorting] = useState<Identifier[]>([] as Identifier[]);

  useEffect(() => {
    if (ids && ids !== filterValues.departments) {
      switch (filterValues.departments) {
        case null:
          setAll(ids);
          break;
        case "Autista":
          setDrivers(ids);
          break;
        case "Ufficio":
          setOffice(ids);
          break;
        case "Magazzino":
          setStore(ids);
          break;
        case "Manutenzione":
          setMaintenance(ids);
          break;
        case "Smistamento":
          setSorting(ids);
          break;
      }
    }
  }, [ids, filterValues.departments]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, departments: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );
  const { data: options } = useOptionsResource("GET");

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.departments}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      {options ? (
        <div>
          {(filterValues.departments == null ||
            filterValues.departments == undefined ||
            filterValues.departments == ""
          ) && (
              <ListContextProvider value={{ ...listContext, ids: all }}>
                <Datagrid {...props}>
                  <IdentifierField {...initField("id", options)} />
                  <TextField {...initField("name", options)} />
                  <TextField {...initField("surname", options)} />
                  <TextField {...initField("site__business_name", options)} label="Sede" />
                </Datagrid>
              </ListContextProvider>
            )}
          {filterValues.departments === "Autista" && (
            <ListContextProvider value={{ ...listContext, ids: drivers }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("license", options)} />
                <TextField {...initField("device", options)} />
                <TextField {...initField("fuel_card_number", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Ufficio" && (
            <ListContextProvider value={{ ...listContext, ids: office }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Magazzino" && (
            <ListContextProvider value={{ ...listContext, ids: store }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Manutenzione" && (
            <ListContextProvider value={{ ...listContext, ids: mainteneance }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Smistamento" && (
            <ListContextProvider value={{ ...listContext, ids: sorting }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

const NoTabbedDatagrid = (props: DatagridProps) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  const [store, setStore] = useState<Identifier[]>([] as Identifier[]);
  const [sorting, setSorting] = useState<Identifier[]>([] as Identifier[]);


  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, departments: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );
  const { data: options } = useOptionsResource("GET");

  return (

    <ListContextProvider value={{ ...listContext, ids: ids }}>
      <Datagrid {...props}>
        <IdentifierField {...initField("id", options)} />
        <TextField {...initField("name", options)} />
        <TextField {...initField("surname", options)} />
        <FunctionField
          label="Reparti" render={
            (record: any) => {
              if (record && record.departments) {
                return record.departments.map(
                  (department: any) => department).join(', ')
              }
              return ''

            }} />

        <TextField {...initField("site__business_name", options)} label="Sede" />
      </Datagrid>
    </ListContextProvider>
  );
};

export const EmployeeList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("name", options)} />,
      <TextInput {...initField("surname", options)} />,
      <ReferenceInput alwaysOn
        {...initField("site", options)}
        reference="autocomplete/site"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceArrayInput alwaysOn
        {...initField("departments", options)}
        reference="autocomplete/department"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,
    ]
    : [];
  return options && getUser().permissions.includes('employees.change_employee') ? (
    <CustomList
      filterDefaultValues={{}}
      filters={filters}
      {...getDefaultListProps(props)}
    >
      <NoTabbedDatagrid />
    </CustomList>
  ) : null;
};
