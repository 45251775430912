import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  ArrayField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  FormDataConsumer,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, dateTimeFormatter, generateError, initField, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import { getPossibleNextStatusDelivery } from "./utils";
import { DeliveryStatusChoice, DeliveryType } from "../../shared/types";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { DeliveryStatusReasonField } from "./DeliveryStatusReasonField";
import { useEffect, useState } from "react";
import { TariffClientDatagrid } from "../../components/TariffClientDatagrid";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";

interface AddDeliveryStatusButtonProps { refreshMain?: boolean | undefined }

export const AddDeliveryStatusButton = (props: AddDeliveryStatusButtonProps) => {
  const record = useRecordContext();
  const { value, setFalse, setTrue } = useToggler();
  const [newDelivery, setNewDelivery] = useState(true);
  const [fault, setFault] = useState();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    create("delivery-states", values, {
      onSuccess: ({ data }) => {
        if (data.new_delivery) {
          const url = `/deliveries/${data.new_delivery}?`;
          window.open(url);
        }
        setFalse();
        refresh();
        if (props.refreshMain) {
          window.location.reload();
        }
      },
      onFailure: (error) => {
        generateError(error, notify);
      },
    });
  };
  const { data: options } = useOptions("delivery-states", "GET");
  const { data: optionsDeliveries } = useOptions("deliveries", "GET");
  const { data: optionsTariffs } = useOptions("tariffs", "GET");

  const [deliveryTypeNew, setDeliveryTypeNew] = useState(DeliveryType.NORMAL)

  return options && optionsTariffs ? (
    <>
      <Button tabIndex={-1} label="Aggiorna stato" onClick={() => setTrue()}>
        <UpdateIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="delivery-states"
          save={handleSubmit}
          initialValues={{ delivery: record.id }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <DateTimeInput
                  initialValue={dateTimeFormatter(new Date())}
                  {...initInput("date", options, true)}
                  showTime={true}
                />
                <SelectInput
                  {...initInput("status", options, true, "array")}
                  choices={getPossibleNextStatusDelivery(record)}
                  onChange={(s: any) => {
                    if (form.getState().values.status === "SE") {
                      form.getState().values.delivery_type_new = DeliveryType.RETURN
                    }
                    else {
                      form.getState().values.delivery_type_new = null;
                    }
                  }}
                />

                {form.getState().values?.status ===
                  DeliveryStatusChoice.STOCKED && (
                    <ReferenceInputSubject
                      {...initInput(
                        "stock_store",
                        true
                      )}
                      validate={required()}
                      label="Magazzino"
                      filter={{ type: "default_store" }}
                    />
                  )}

                <DeliveryStatusReasonField
                  options={options}
                  setFault={setFault}
                  form={form}
                />
                <TextInput {...initInput("status_notes", options, true)} />

                {form.getState().values?.status ===
                  DeliveryStatusChoice.STOCKED_END && (
                    <BooleanInput
                      source="cancel_new_delivery"
                      label="Da Annullare"
                      defaultValue={false}
                      onChange={(value: any) => {
                        setNewDelivery(!value);
                      }}
                    />
                  )}
                {
                  (
                    form.getState().values?.status === DeliveryStatusChoice.STOCKED_END
                  ) &&
                  newDelivery &&
                  optionsTariffs && (

                    <>
                      <SelectInput
                        {...initInput(
                          "delivery_type_new",
                          {},
                          true,
                          "array"
                        )}
                        label="Tipo missione figlia"
                        defaultValue={null}
                        choices={optionsDeliveries.delivery_type.choices}
                        onChange={(value: any) => {
                          form.change("create_return", null);
                          setDeliveryTypeNew(value.target.value);
                          if (value.target.value === DeliveryType.BACK && (record.delivery_type === DeliveryType.NORMAL || record.delivery_type === DeliveryType.RETURN)) {
                            form.change("new_sender", record.receiver);
                          }
                          else if (value.target.value === DeliveryType.BACK && record.delivery_type === DeliveryType.BACK) {
                            form.change("new_sender", record.sender);
                          }
                        }}
                      />

                      <BooleanInput
                        source="exclude_first_segment"
                        label="Escludi prima tratta"
                        fullWidth
                      />
                      {
                        form.change("exclude_first_segment", (
                          (form.getState().values?.delivery_type_new === DeliveryType.NORMAL && record.delivery_type === DeliveryType.WITHDRAW) ||
                          (form.getState().values?.delivery_type_new === DeliveryType.BACK && record.delivery_type === DeliveryType.WITHDRAW)
                        ) ? true : record.exclude_first_segment)
                      }
                      <BooleanInput
                        source="exclude_last_segment"
                        label="Escludi ultima tratta"
                        fullWidth
                      />
                      {
                        form.change("exclude_last_segment", (
                          (form.getState().values?.delivery_type_new === DeliveryType.NORMAL && record.delivery_type === DeliveryType.WITHDRAW) ||
                          (form.getState().values?.delivery_type_new === DeliveryType.BACK && record.delivery_type === DeliveryType.WITHDRAW)
                        ) ? false : record.exclude_last_segment)
                      }
                      <BooleanInput
                        source="exclude_all_segment"
                        label="Escludi tutte le tratte"
                        fullWidth
                      />

                      <FormDataConsumer>
                        {({ formData }) => (
                          <ReferenceInputSubject
                            {...initInput(
                              "new_sender",
                              true
                            )}
                            required={(formData.delivery_type_new === DeliveryType.BACK || formData.delivery_type_new === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                            label="Nuovo mittente"
                          />
                        )}
                      </FormDataConsumer>

                      <ReferenceInputSubject
                        {...initInput(
                          "new_receiver",
                          true
                        )}
                        filter={{ type: "receiver" }}
                        validate={required()}
                        label="Nuovo destinatario"
                      />
                      <FormDataConsumer>
                        {({ formData }) => (
                          <TextInput
                            source="new_document_number"
                            label="Nuovo numero documento"
                            fullWidth
                            required={(formData.delivery_type_new === DeliveryType.BACK || formData.delivery_type_new === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                            defaultValue={formData.document_number}
                          />)}
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData }) => (
                          <DateInput
                            {...initInput(
                              "new_document_date",
                              true
                            )}
                            label="Nuova data documneto"
                            required={(formData.delivery_type_new === DeliveryType.BACK || formData.delivery_type_new === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                            defaultValue={formData.document_date}
                          />)}
                      </FormDataConsumer>


                      {!fault ? (
                        <FormDataConsumer>
                          {({ formData }) => (
                            <ReferenceInput
                              source="create_return"
                              reference="tariffs-client"
                              label="Nuova tariffa"
                              filter={{
                                delivery: record.id,
                                client: record.client,
                                document_date: record.document_date,
                                delivery_type: deliveryTypeNew,
                                receiver: record.receiver,
                                starting_point_on_delivery: record.starting_point_on_delivery
                              }}
                              fullWidth
                              isRequired={(formData.delivery_type_new === DeliveryType.BACK || formData.delivery_type_new === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                            >
                              <DialogSelectInput optionText="label">
                                <TariffClientDatagrid options={optionsTariffs} />
                              </DialogSelectInput>
                            </ReferenceInput>)}
                        </FormDataConsumer>) : null
                      }
                    </>
                  )}



              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
