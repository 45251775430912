import {
  BulkDeleteButtonProps,
  BulkUpdateButton,
  Button,
  ButtonProps,
  FormWithRedirect,
  Identifier,
  SelectInput,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { ActionsToolbar } from "../../components/ActionsToolbar";
import IconCancel from "@material-ui/icons/Cancel";

import BlockIcon from "@material-ui/icons/Block";
import { client } from "../../dataProvider";
import { Dialog, DialogContent, DialogActions, CircularProgress } from "@material-ui/core";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useToggler } from "../../shared/hooks/useToggler";
import { useState } from "react";



const OpenCharge = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();
  const handleSubmit = async (e: any) => {
    e.stopPropagation();

    /**/

    let urlParams: any = new URLSearchParams(window.location.search);
    if (urlParams.has("filter")) {
      let filter = urlParams.get("filter");
      let json_filter = JSON.parse(filter)
      json_filter.clients = `[${selectedIds.join(",")}]`
      let json_string = JSON.stringify(json_filter)
      json_string = json_string.replace(`"[`, `[`).replace(`]"`, `]`)
      urlParams.set("filter", json_string)
    }
    else {
      let json_string = JSON.stringify({
        "clients":
          `[${selectedIds.join(",")}]`
      })
      json_string = json_string.replace(`"[`, `[`).replace(`]"`, `]`)
      urlParams.set("filter", json_string)
    }
    window.open("/verified-charges?" + urlParams.toString())

  };

  return (
    <Button
      {...props}
      disabled={loading}
      label="Apri addbiti clienti"
      title="Apri addbiti clienti "
      onClick={handleSubmit}
    >
      <></>
    </Button>
  );
};


export const BulkActions = (props: BulkDeleteButtonProps) => {

  //<PrintLabelButton />
  return (
    <>
      <OpenCharge />
    </>
  );
};