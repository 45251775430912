import { AutocompleteInput, Button, Datagrid, DateField, DateTimeInput, FormField, FunctionField, ListProps, NumberInput, Record, ReferenceInput, SelectField, SelectInput, SimpleForm, TextField, TextInput, useRefresh } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { DriverField } from "../../components/DriverField";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { getUser } from "../../authProvider";

export const PresenceOnlyInList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const user = getUser();
  const refresh = useRefresh();

  var counter = 0
  let limitTime = 24 * 60 * 60 * 1000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter < limitTime && (["admin"].includes(user.username) || user.username.toLowerCase().includes("bacheca"))) {
        refresh();

        counter = counter + 120000
      }

    }, 120000);

    return () => clearInterval(timer);
  }, []);




  return options ? (
    <CustomList {...getDefaultListProps(props)}
      sort={{ field: "id", order: "DESC" }}
      filterDefaultValues={
        {
          department: "Autista",
          logged_type: "only_in"
        }
      }
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <EmployeeField {...initField("employee_object", options)} label="Operatore" />
        <TextField {...initField("departments", options)} label="Reparti" />

        <DateField {...initField("date_in", options)} label="GIORNO"
          options={{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
          }} />

        <FunctionField
          label="ENTRATA"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              const date_in = tableRecord["date_in"];
              if (date_in) {
                return (
                  <Typography variant="body2" style={{ color: "black" }}>
                    {format(new Date(date_in), "HH:mm:SS")}
                  </Typography>)
              }
            }
            return null;
          }}
          sortable={false}
        />

      </Datagrid>
    </CustomList >
  ) : null;
};

