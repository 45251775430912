import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TableContainer,
  Typography,
  createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteButton,
  FormDataConsumer,
  FormWithRedirect,
  FormWithRedirectRenderProps,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceInput,
  ReferenceManyField,
  refreshSaga,
  required,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useRecordContext,
  useRefresh,
  Record
} from "react-admin";
import { OnChange } from "react-final-form-listeners";
import { CustomToolbar } from "../../components/CustomToolbar";
import { DeliveryField } from "../../components/DeliveryField";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { DeliveryType, FormProps, Subject } from "../../shared/types";
import { initField, initInput } from "../../shared/utils";
import { RouteDatagrid } from "../Route/RouteDatagrid";
import { AddDeliveryItemForm } from "./AddDeliveryItemForm";
import { AddDeliveryStatusButton } from "./AddDeliveryStatusButton";
import { DeliveryAddTariffField } from "./DeliveryAddTariffField";
import { DeliveryItemDatagrid } from "./DeliveryItemDatagrid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DriverField } from "../../components/DriverField";
import { IdentifierField } from "../../components/IdentifierField";
import { SubjectField } from "../../components/SubjectField";
import { CustomFileField } from "../../components/CustomFIleField";
import { RemittanceField } from "../../components/RemittanceField";
import { DeliveryDatagrid } from "./DeliveryDatagrid";
import { RevenuesOnFly } from "./RevenuesOnFly";
import { RevenuesSingleDelivery } from "./RevenuesSingleDelivery";
import { NumberInput } from "../../components/NumberInput";
import { RecurrentField } from "../../components/RecurrentField";
import { UpdateReasonDeliveryStatusButton } from "./UpdateReasonDeliveryStatusButton";
import { BooleanPartialFieldCustom } from "../../components/BooleanPartialFieldCustom";
import { DeliveryStatusSelectField } from "../../components/DeliveryStatusShowField";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { AddDeliveryAttachmentButton } from "./AddDeliveryAttachmentButton";
import { DeleteDeliveryStatusButton } from "./DeleteDeliveryStatusButton";
import { DeliveryNoteRowDatagrid } from "./DeliveryNoteRowDatagrid";
import { AddDeliveryNoteRowForm } from "./AddDeliveryNoteRowForm";
import { isEmpty, memoize, set } from "lodash";
import { MicroAsideLog } from "../../components/MicroAsideLog";
import { PERMISSIONS } from "../../constants";
import { getUser } from "../../authProvider";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { SimpleSegmentDatagrid } from "./SimpleSegmentDatagrid";
import TodayIcon from '@material-ui/icons/Today';
import { AddDeliveryStatusStartEndStockButton } from "./AddDeliveryStatusStartEndStockButton";

const useStyles = makeStyles((theme) => ({
  "spacing-xs-2": {
    "& > .MuiGrid-item	": {
      padding: "0px 8px",
    },
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  button: {
    // main styles,
    "&:focus": {
      border: "1px red solid",
      color: "red",
    }
  }
}));

export const DeliveryForm = (props: FormProps) => {
  const { data: optionsCachet } = useOptions("cachets", "GET");
  const { data: optionsDeliveryItems } = useOptions("delivery-items", "GET");
  const { data: optionsDeliveryNoteRows } = useOptions("delivery-note-rows", "GET");
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");
  const { data: optionsRoutes } = useOptions("routes", "GET");
  const { data: optionsSegments } = useOptions("segments", "GET");
  const { data: optionsNotes } = useOptions("notes", "GET");
  const { data: optionsDelivery } = useOptions("deliveries", "GET");

  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [checkedStartingPoinOnDelivery, setCheckedStartingPoinOnDelivery] = useState(false);
  const [checkedSender, setCheckedSender] = useState(false);
  const [checkedReceiver, setCheckedReceiver] = useState(false);
  const [calculateRevenues, setCalculateRevenues] = useState(0);
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (record) {
      setCheckedReceiver(
        record.receiver ? (record.client === record.receiver) : false
      );
      if (record.sender && record.client && (record.client === record.sender || record.client === record.main_subject?.id))
        setCheckedSender(true);
    }
  }, [record]);

  const updateStartingPoinOnDelivery = (
    value: number,
    formProps: FormWithRedirectRenderProps
  ) => {
    dataProvider
      .getOne<Subject>("subjects", {
        id: value,
      })
      .then(({ data: subject }) => {
        if (subject.starting_point_id) {
          if (subject.starting_point?.type.includes("destinatario")) {
            formProps.form.change("starting_point_on_delivery", subject.starting_point_id);
          }
        } else {
          if (subject?.type.includes("destinatario")) {
            formProps.form.change("starting_point_on_delivery", subject.id);
          }
        }
      });
  };

  const updateStartingPoinOnDeliveryBack = (
    value: number,
    formProps: FormWithRedirectRenderProps
  ) => {
    if (value === 0 && formProps.form.getState().values.client != null && formProps.form.getState().values.client != undefined) {
      value = formProps.form.getState().values.client
    }
    if (value && formProps.form.getState().values.delivery_type === DeliveryType.BACK) {
      dataProvider
        .getOne<Subject>("subjects", {
          id: value,
        })
        .then(({ data: subject }) => {
          if (subject.back_point_id) {
            formProps.form.change("starting_point_on_delivery", subject.back_point_id);
          }
          else {
            formProps.form.change("starting_point_on_delivery", null);
          }
        });
    }
    else {
      formProps.form.change("starting_point_on_delivery", null);
    }

  };


  const setToday = (
    value: string,
    field: string,
    form: FormProps
  ) => {
    form.change(field, value);
  };


  const updateSender = (
    value: number,
    formProps: FormWithRedirectRenderProps
  ) => {
    dataProvider
      .getOne<Subject>("subjects", {
        id: value,
      })
      .then(({ data: subject }) => {
        if (subject.starting_point_id) {
          formProps.form.change("sender", subject.starting_point_id);
        } else {
          formProps.form.change("sender", subject.id);
        }
      });
  };

  const updateReceiver = (
    value: number,
    formProps: FormWithRedirectRenderProps
  ) => {
    dataProvider
      .getOne<Subject>("subjects", {
        id: value,
      })
      .then(({ data: subject }) => {
        if (subject.starting_point_id) {
          if (subject.starting_point?.type.includes("destinatario")) {
            formProps.form.change("receiver", subject.starting_point_id);
          }
        } else {
          if (subject?.type.includes("destinatario")) {
            formProps.form.change("receiver", subject.id);
          }
        }
      });
  };

  const updateClientRelated = (
    value: number,
    formProps: FormWithRedirectRenderProps
  ) => {
    dataProvider
      .getOne<Subject>("subjects", {
        id: value,
      })
      .then(({ data: subject }) => {
        formProps.form.change("internal_notes", subject.internal_notes);
        if (
          formProps.form.getState().values?.delivery_type === DeliveryType.WITHDRAW ||
          formProps.form.getState().values?.delivery_type === DeliveryType.BACK
        ) {
          formProps.form.change("to_be_tractioned", false);
        } else {
          formProps.form.change("to_be_tractioned", subject.to_be_tractioned);
        }
      });
  };

  const numDDTRequired = memoize((message = 'ra.validation.required') =>
    Object.assign(
      (value: any, values: any) => {
        return ![DeliveryType.WITHDRAW, DeliveryType.BACK].includes(
          values["delivery_type"]
        ) && isEmpty(value) ? "Inserire Numero DDT"
          : undefined
      },
      (value: any, values: any) => {
        return {
          isRequired: ![DeliveryType.WITHDRAW, DeliveryType.BACK].includes(
            values["delivery_type"]
          )
        }
      }


    )
  );

  const integerRequired = memoize((message = 'ra.validation.required') =>
    Object.assign(
      (value: any, values: any) => {
        return !Number.isInteger(value) ? "Inserire un numero Intero"
          : undefined
      }
    )
  );

  const [accrodinonCachet, setAccrodinonCachet] = useState(false);
  const [accrodinonSegment, setAccrodinonSegment] = useState(false);
  const [accrodinonRoutePlanned, setAccrodinonRoutePlanned] = useState(false);
  const [accrodinonDeliveryParents, setAccrodinonDeliveryParents] = useState(false);
  const [accrodinonNotes, setAccrodinonNotes] = useState(false);
  const [accrodinonAttachments, setAccrodinonAttachments] = useState(false);
  //const [accrodinonRecurrents, setAccrodinonRecurrents] = useState(false);
  const [showStatesLogs, setShowStatesLogs] = useState(false);

  return props.fieldOptions ? (
    <FormWithRedirect
      {...props}
      initialValues={{
        is_recurrent: record && record.recurrence_conf ? true : false,
        delivery_date: new Date()
      }}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Info generali
              </Typography>
              <Grid container classes={classes} spacing={2}>
                <Grid item xs={6}>
                  <SelectInput
                    {...initInput(
                      "delivery_type",
                      props.fieldOptions,
                      props.canEdit,
                      "array"
                    )}
                    defaultValue={DeliveryType.NORMAL}
                    choices={props.fieldOptions.delivery_type.choices}
                    onChange={(e) => {
                      updateStartingPoinOnDeliveryBack(0, formProps)
                      if (e.target.value !== DeliveryType.PLACEHOLDER) {
                        formProps.form.change("placeholder_type", null);
                      }
                      if (e.target.value === DeliveryType.WITHDRAW || e.target.value === DeliveryType.BACK) {
                        formProps.form.change("to_be_tractioned", false);
                        formProps.form.change("exclude_first_segment", true);
                        formProps.form.change("exclude_last_segment", false);
                        if (e.target.value === DeliveryType.WITHDRAW) {
                          formProps.form.change("exclude_first_segment", false);
                          formProps.form.change("exclude_last_segment", true);
                        }
                        setCheckedStartingPoinOnDelivery(false);
                        setCheckedSender(false);
                      }
                      else {
                        if (e.target.value === DeliveryType.PLACEHOLDER) {
                          formProps.form.change("packages_number", 0);
                          formProps.form.change("weight", null);
                        }
                        formProps.form.change("exclude_first_segment", true);
                        formProps.form.change("exclude_last_segment", false);
                        setCheckedStartingPoinOnDelivery(false);
                        setCheckedSender(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimeInput
                    {...initInput(
                      "delivery_date",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    inputProps={{ tabIndex: -1 }}
                    validate={required()}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <ReferenceInputSubject
                        {...initInput(
                          "client",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        filter={{ type: "client" }}
                        validate={required()}
                      />
                    )}
                  </FormDataConsumer>
                </Grid>

                <Grid item xs={6} container direction="row">
                  <Grid item xs={4}>
                    <DateInput
                      {...initInput(
                        "document_date",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      validate={required()}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormDataConsumer>
                      {({ formData }) => (
                        <FormControlLabel
                          style={{ margin: "10px" }}
                          control={
                            <Button
                              classes={buttonClasses}
                              color="inherit"
                              onClick={() => {
                                setToday(
                                  `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`, "document_date", formProps.form)
                              }}>
                              <TodayIcon fontSize="large" />
                            </Button>
                          }
                          label={"Oggi"}
                        />
                      )}
                    </FormDataConsumer>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      {...initInput(
                        "document_number",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      required={![DeliveryType.WITHDRAW, DeliveryType.BACK].includes(
                        formProps.form.getFieldState("delivery_type")?.value
                      )}
                      validate={numDDTRequired()}
                    />
                    {

                    }
                  </Grid>
                </Grid>

                <OnChange name="client">
                  {(value, previous) => {
                    updateStartingPoinOnDeliveryBack(value, formProps)
                    if (checkedStartingPoinOnDelivery && value) {
                      updateStartingPoinOnDelivery(value, formProps);
                    }
                    if (checkedSender && value) {
                      updateSender(value, formProps);
                    }
                    if (value) {
                      updateClientRelated(value, formProps);
                    }
                  }}
                </OnChange>

                <Grid item xs={6}>
                  <Grid item container justifyContent="center">
                    <Grid item>
                      <BooleanInput
                        {...initInput(
                          "exclude_first_segment",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        inputProps={{ tabIndex: -1 }}
                        defaultChecked={true}
                        defaultValue={true}
                      />
                    </Grid>
                    <Grid item>
                      <BooleanInput
                        {...initInput(
                          "exclude_last_segment",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        inputProps={{ tabIndex: -1 }}
                      />
                    </Grid>
                    <Grid item>
                      <BooleanInput
                        {...initInput(
                          "exclude_all_segment",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        inputProps={{ tabIndex: -1 }}
                      />

                    </Grid>
                  </Grid>
                </Grid>


                <Grid item xs={6} container direction="column">
                  <Grid item container justifyContent="center">
                    <Grid item>
                      <FormDataConsumer>
                        {({ formData }) => (
                          <FormControlLabel
                            style={{ margin: "0px" }}
                            control={
                              <Checkbox
                                checked={checkedStartingPoinOnDelivery}
                                disabled={
                                  initInput(
                                    "starting_point_on_delivery",
                                    props.fieldOptions,
                                    props.canEdit
                                  ).disabled
                                }
                                onChange={(e, value) => {
                                  setCheckedStartingPoinOnDelivery(value);
                                  if (value) {
                                    const client =
                                      formProps.form.getFieldState("client");
                                    if (client && client.value) {
                                      updateStartingPoinOnDelivery(client.value, formProps);
                                    }
                                  }
                                  else {
                                    formProps.form.change("starting_point_on_delivery", null);
                                  }
                                }}
                                inputProps={{ tabIndex: -1 }}
                              />
                            }
                            label={
                              (formData.delivery_type === DeliveryType.WITHDRAW || formData.delivery_type === DeliveryType.BACK) ?
                                "Destinazione" : "P. partenza consegna"}
                          />
                        )}
                      </FormDataConsumer>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        style={{ margin: "0px" }}
                        control={
                          <Checkbox
                            checked={checkedSender}
                            disabled={
                              initInput(
                                "sender",
                                props.fieldOptions,
                                props.canEdit
                              ).disabled
                            }
                            onChange={(e, value) => {
                              setCheckedSender(value);
                              if (value) {
                                const client =
                                  formProps.form.getFieldState("client");
                                if (client && client.value) {
                                  updateSender(client.value, formProps);
                                }
                              }
                              else {
                                formProps.form.change("sender", null);
                              }
                            }}
                            inputProps={{ tabIndex: -1 }}
                          />
                        }
                        label="Mittente"
                      />
                    </Grid>

                    <FormDataConsumer>
                      {({ formData }) => (
                        <Grid item>
                          <FormControlLabel
                            style={{ margin: "0px" }}
                            control={
                              <Checkbox
                                disabled={
                                  initInput(
                                    "receiver",
                                    props.fieldOptions,
                                    props.canEdit
                                  ).disabled
                                }
                                checked={checkedReceiver}
                                onChange={(e, value) => {
                                  setCheckedReceiver(value);
                                  if (value) {
                                    const client =
                                      formProps.form.getFieldState("client");
                                    if (client && client.value) {
                                      updateReceiver(client.value, formProps);
                                    }
                                  }
                                  else {
                                    formProps.form.change("receiver", null);
                                  }
                                }}
                                inputProps={{ tabIndex: -1 }}
                              />
                            }
                            label={
                              (formData.delivery_type === DeliveryType.WITHDRAW || formData.delivery_type === DeliveryType.BACK) ?
                                "Punto ritiro merce" : "Destinazione"}
                          />
                        </Grid>
                      )}
                    </FormDataConsumer>
                  </Grid>
                  <FormHelperText>
                    <span>&#8203;</span>
                  </FormHelperText>
                </Grid>

                <FormDataConsumer>
                  {({ formData }) => (
                    <Grid item xs={6}>
                      {
                        (formData.delivery_type === DeliveryType.WITHDRAW ||
                          formData.delivery_type === DeliveryType.BACK) ? (

                          <Grid container>

                            <Grid item xs={12}>
                              <OnChange name="client">
                                {(value, previous) => {
                                  if (checkedReceiver && value) {
                                    updateReceiver(value, formProps);
                                  }
                                }}
                              </OnChange>
                              <FormDataConsumer>
                                {({ formData }) => (
                                  <ReferenceInputSubject
                                    {...initInput(
                                      "receiver",
                                      props.fieldOptions,
                                      props.canEdit
                                    )}
                                    filter={{ type: "receiver" }}
                                    label="Punto di ritiro della merce"
                                  />
                                )}
                              </FormDataConsumer>
                            </Grid>

                            <Grid item xs={12}>
                              <ReferenceInputSubject
                                {...initInput(
                                  "transit_point",
                                  props.fieldOptions,
                                  props.canEdit
                                )}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <ReferenceInputSubject
                                {...initInput(
                                  "starting_point_on_delivery",
                                  props.fieldOptions,
                                  props.canEdit
                                )}
                                label="Destinazione merce"
                                filter={{ type: checkedStartingPoinOnDelivery ? "receiver,store" : "receiver" }}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container>

                            <Grid item xs={12}>
                              <ReferenceInputSubject
                                {...initInput(
                                  "starting_point_on_delivery",
                                  props.fieldOptions,
                                  props.canEdit
                                )}
                                filter={{
                                  type: checkedStartingPoinOnDelivery ? "receiver,store" : "receiver",
                                  ...(checkedStartingPoinOnDelivery
                                    ? { subject: formData.client }
                                    : {}),
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <OnChange name="client">
                                {(value, previous) => {
                                  if (checkedReceiver && value) {
                                    updateReceiver(value, formProps);
                                  }
                                }}
                              </OnChange>

                              <Grid item xs={12}>
                                <ReferenceInputSubject
                                  {...initInput(
                                    "transit_point",
                                    props.fieldOptions,
                                    props.canEdit
                                  )}
                                />
                              </Grid>

                              <FormDataConsumer>
                                {({ formData }) => (
                                  <ReferenceInputSubject
                                    {...initInput(
                                      "receiver",
                                      props.fieldOptions,
                                      props.canEdit
                                    )}
                                    filter={{ type: "receiver" }}
                                  />
                                )}
                              </FormDataConsumer>
                            </Grid>
                          </Grid>
                        )
                      }
                    </Grid>

                  )}
                </FormDataConsumer>


                <Grid item xs={6}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <ReferenceInputSubject
                        {...initInput(
                          "sender",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        filter={{
                          type: "store",
                          ...(checkedSender
                            ? { subject: formData.client }
                            : {}),
                        }}
                        isRequired={true}
                      />)}
                  </FormDataConsumer>
                  <TextInput
                    {...initInput(
                      "sub_client",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    inputProps={{ tabIndex: -1 }}
                  />
                  <ReferenceInputSubject
                    {...initInput(
                      "cessionary",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    filter={{ type: "receiver" }}
                    options={{
                      suggestionsContainerProps: undefined,
                      labelProps: undefined,
                      InputProps: { inputProps: { tabIndex: -1 } },
                    }}

                  />
                </Grid>


              </Grid>
              <Grid container classes={classes} spacing={2}>
                <Grid item xs={6}>
                  <Grid container classes={classes} spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Info trasporto
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <NumberInput
                        {...initInput(
                          "packages_number",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        required={true}
                        onWheel={(e: any) => { e.target.blur() }}
                        validate={integerRequired()}
                        onChange={(e) => {
                          if (formProps.form.getFieldState("delivery_type") && formProps.form.getFieldState("delivery_type")!.value === DeliveryType.PLACEHOLDER) {
                            formProps.form.change("packages_number", 0);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NumberInput
                        {...initInput(
                          "weight",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        onWheel={(e: any) => { e.target.blur() }}
                        onChange={(e) => {
                          if (formProps.form.getFieldState("delivery_type") && formProps.form.getFieldState("delivery_type")!.value === DeliveryType.PLACEHOLDER) {
                            formProps.form.change("weight", null);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        {...initInput(
                          "volume",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        onWheel={(e: any) => { e.target.blur() }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        {...initInput(
                          "value",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        onWheel={(e: any) => { e.target.blur() }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInput
                        {...initInput(
                          "insurance",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        onWheel={(e: any) => { e.target.blur() }}
                      />
                    </Grid>

                    <FormDataConsumer>
                      {({ formData }) => (
                        formData.delivery_type !== DeliveryType.PLACEHOLDER ?
                          <Grid item xs={12}>
                            <NumberInput
                              {...initInput(
                                "cachet",
                                props.fieldOptions,
                                props.canEdit
                              )}
                              onWheel={(e: any) => { e.target.blur() }}
                            />
                          </Grid> : <Grid item xs={12}>
                            <SelectInput
                              {...initInput(
                                "placeholder_type",
                                props.fieldOptions,
                                props.canEdit,
                                "array"
                              )}
                              choices={props.fieldOptions.placeholder_type.choices}
                            />
                          </Grid>
                      )}
                    </FormDataConsumer>

                    <FormDataConsumer>
                      {({ formData }) => (
                        (!props.record || !props.record.id) && formData.delivery_type !== DeliveryType.PLACEHOLDER && (
                          <Grid item xs={12}>
                            <DeliveryAddTariffField options={props.fieldOptions} />
                          </Grid>
                        ))}
                    </FormDataConsumer>


                    <Grid container item xs={12}>

                      <Grid item xs={4}>
                        <BooleanInput
                          {...initInput(
                            "same_day",
                            props.fieldOptions,
                            props.canEdit
                          )}
                          inputProps={{ tabIndex: -1 }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <BooleanInput
                          {...initInput(
                            "internal_notes",
                            props.fieldOptions,
                            props.canEdit
                          )}
                          inputProps={{ tabIndex: -1 }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <BooleanInput
                          {...initInput(
                            "partial",
                            props.fieldOptions,
                            props.canEdit
                          )}
                          inputProps={{ tabIndex: -1 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <BooleanInput
                          {...initInput(
                            "hide",
                            props.fieldOptions,
                            props.canEdit
                          )}
                          inputProps={{ tabIndex: -1 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormDataConsumer>
                          {({ formData }) => (
                            <BooleanInput
                              {...initInput(
                                "to_be_tractioned",
                                props.fieldOptions,
                                props.canEdit
                              )}
                              disabled={
                                (formData.delivery_type === DeliveryType.WITHDRAW ||
                                  formData.delivery_type === DeliveryType.BACK)
                                  ? true
                                  : false
                              }
                              inputProps={{ tabIndex: -1 }}
                            />
                          )}
                        </FormDataConsumer>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={6} container direction="column">
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Info secondarie
                    </Typography>
                  </Grid>
                  {props.record && props.record.id && (
                    <>
                      {props.record.parent_delivery && (
                        <Grid item>
                          <Typography>Missione precedente</Typography>
                          <DeliveryField
                            record={{ id: props.record.parent_delivery }}
                            deliverySource="id"
                          />
                        </Grid>
                      )}
                      {props.record.main_delivery && (
                        <Grid item>
                          <Typography>Missione iniziale</Typography>
                          <DeliveryField
                            record={{ id: props.record.main_delivery }}
                            deliverySource="id"
                            label={"Prima missione"}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <ReferenceInput
                          {...initInput(
                            "parent_delivery",
                            props.fieldOptions,
                            props.canEdit
                          )}
                          reference="autocomplete/delivery"
                        >
                          <AutocompleteInput optionText="label" />
                        </ReferenceInput>
                      </Grid>
                      <Grid item>
                        <DateField
                          showTime
                          {...initField("evasion_date", props.fieldOptions)}
                        />
                      </Grid>
                      <Grid item>
                        <DateField
                          showTime
                          {...initField("bill_sent", props.fieldOptions)}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <FormDataConsumer>
                      {({ formData }) =>
                        <TextInput
                          multiline
                          {...initInput("notes", props.fieldOptions, props.canEdit)}
                          inputProps={{ tabIndex: -1 }}
                          isRequired={formData.partial}
                        />
                      }
                    </FormDataConsumer>
                  </Grid>
                  <Grid item>
                    <TextInput
                      multiline
                      {...initInput("aspect", props.fieldOptions, props.canEdit)}
                      inputProps={{ tabIndex: -1 }}
                    />
                  </Grid>

                  <Grid item container direction="row">
                    <Grid item container direction="row" xs={12}>
                      <Grid item xs>
                        <Typography variant="h6" gutterBottom>
                          Missione ricorrente
                        </Typography>
                      </Grid>
                      <Grid item>
                        <BooleanInput label={false} source="is_recurrent"
                          inputProps={{ tabIndex: -1 }}
                        />
                      </Grid>
                    </Grid>
                    <FormDataConsumer>
                      {({ formData }) =>
                        formData?.is_recurrent && (
                          <Grid item xs={12}>
                            <RecurrentField />
                          </Grid>
                        )
                      }
                    </FormDataConsumer>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" align="center">
                      {!props.record || !props.record.id ? (
                        <RevenuesOnFly />
                      ) : (
                        <RevenuesSingleDelivery key={calculateRevenues} />
                      )}
                    </Typography>
                    <br />
                  </Grid>
                </Grid>
              </Grid>
              <Box mx={"-20px"} mb={"-30px"}>
                <Grid container>
                  <Grid item xs={12}>
                    {props.record && props.record.id && getUser().permissions.includes('distribution.view_deliveryitem') && optionsDeliveryItems && (
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panela-content"
                          id="panela-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Servizi
                          </Typography>
                          {
                            record && record.price_warning === true ?
                              <Typography variant="h6" gutterBottom color="error">
                                (Attenzione, la somma dei colli/peso non corrisponde al totale dei serivzi di trasporto)
                              </Typography> :
                              null
                          }
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}>
                          {
                            props.record.delivery_type !== DeliveryType.PLACEHOLDER &&
                            <AddDeliveryItemForm />
                          }
                          <TableContainer>
                            <ReferenceArrayField
                              label="Pacchi"
                              reference="delivery-items"
                              source="deliveryitem_set"
                            >
                              <DeliveryItemDatagrid
                                options={optionsDeliveryItems}
                                updateRevenues={() => {
                                  setCalculateRevenues((old) => old + 1);
                                  refresh();
                                }}
                              />
                            </ReferenceArrayField>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {props.record && props.record.id && getUser().permissions.includes('cash_on_delivery.display_cachet_to_cash_in') && optionsCachet && (
                      <Accordion
                        expanded={accrodinonCachet}
                        onClick={() => setAccrodinonCachet(!accrodinonCachet)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6">Contrassegni</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}
                          onClick={(e) => { e.stopPropagation() }}>
                          {accrodinonCachet ?
                            <Grid item xs={12}>
                              <TableContainer>
                                <ReferenceManyField
                                  label="Contrassegno"
                                  reference="cachets"
                                  target="delivery"
                                >
                                  <Datagrid>
                                    <IdentifierField
                                      {...initField("id", optionsCachet)}
                                    />
                                    <RemittanceField
                                      {...initField("remittance", optionsCachet)}
                                    />

                                    <DeliveryField
                                      deliverySource={"delivery.id"}
                                      {...initField("delivery", optionsCachet)}
                                    />
                                    <ArrayField source="delivery.routes" label="Giro">
                                      <Datagrid>
                                        <DriverField
                                          label="Autista"
                                          source="driver"
                                        />
                                        <TextField source="route" label="Giro" />
                                      </Datagrid>
                                    </ArrayField>
                                    <TextField
                                      {...initField(
                                        "delivery.document_number",
                                        optionsCachet
                                      )}
                                      label="N. documento"
                                    />
                                    <DateField
                                      {...initField(
                                        "delivery.document_date",
                                        optionsCachet
                                      )}
                                      label="Data documento"
                                    />
                                    <SubjectField
                                      source="delivery.client"
                                      label="Cliente"
                                    />
                                    <TextField
                                      {...initField("total", optionsCachet)}
                                    />

                                    <NumberField
                                      {...initField("cash", optionsCachet)}
                                    />
                                    <NumberField
                                      {...initField("check", optionsCachet)}
                                    />
                                    <NumberField
                                      {...initField("rebate", optionsCachet)}
                                    />

                                    <TextField
                                      {...initField("note", optionsCachet)}
                                    />
                                    <DateField
                                      {...initField("collection_date", optionsCachet)}
                                    />
                                  </Datagrid>
                                </ReferenceManyField>
                              </TableContainer>
                            </Grid> :
                            null
                          }
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {props.record && props.record.id && optionsDeliveryStates && optionsDelivery && (
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Storico eventi
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}>
                          <Grid container item xs={12} justifyContent="flex-end">
                            <Grid item>
                              <Button onClick={() => setShowStatesLogs(!showStatesLogs)}>
                                <Typography>{showStatesLogs ? "Nascondi" : "Mostra"} log</Typography>
                              </Button>
                            </Grid>
                            <Grid item>
                              <AddDeliveryStatusStartEndStockButton />
                            </Grid>
                            <Grid item>
                              <AddDeliveryStatusButton />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <TableContainer>
                              <ReferenceManyField
                                reference="delivery-states"
                                target="related_delivery"
                              >
                                <Datagrid rowStyle={(p: any) => {
                                  if (p.deleted === true)
                                    return { textDecoration: 'line-through' }
                                }}>
                                  <DeleteDeliveryStatusButton />
                                  <UpdateReasonDeliveryStatusButton />

                                  <DeliveryStatusSelectField
                                    optionsDeliveryStates={optionsDeliveryStates}
                                    status_field="status"
                                    label="Status"
                                  />
                                  <DeliveryStatusSelectField
                                    optionsDeliveryStates={optionsDeliveryStates}
                                    status_field="sub_status"
                                    label="Sub stato transito"
                                  />

                                  <BooleanPartialFieldCustom props={{ ...props.record }} />
                                  <BooleanField {...initField("partial_segment", optionsDeliveryStates)} />
                                  <TextField {...initField("reason_description", optionsDeliveryStates)} />
                                  <TextField {...initField("status_notes", optionsDeliveryStates)} />
                                  <SubjectField {...initField("stock_store_object", optionsDeliveryStates)} />
                                  <DateField
                                    {...initField("date", optionsDeliveryStates)}
                                    showTime={true}
                                  />
                                  <DeliveryField
                                    deliverySource={"delivery"} {...initField("delivery", optionsDeliveryStates)} noTitle={true} />
                                  <DeliveryTypeField source="delivery_type" options={optionsDelivery} label={"Tipo"} />
                                  <TextField {...initField("info", optionsDeliveryStates)} />
                                  {
                                    showStatesLogs &&
                                    <FunctionField
                                      tabIndex={-1}
                                      sortable={false}
                                      label="Logs"
                                      render={(tableRecord: Record | undefined) => {
                                        if (tableRecord) {
                                          return (
                                            <MicroAsideLog />
                                          );
                                        }
                                        return null;
                                      }}
                                    />}
                                </Datagrid>
                              </ReferenceManyField>
                            </TableContainer>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                  {/*
                  <Grid item xs={12}>
                    {props.record &&
                      props.record.id &&
                      props.record.recurrence_conf &&
                      optionsDelivery && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                          >
                            <Typography variant="h6" gutterBottom>
                              Occorrenze missioni
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ display: "block" }}>
                            <Grid item xs={12}>
                              <TableContainer>
                                <ReferenceManyField
                                  reference="deliveries"
                                  target="recurrence_delivery"
                                >
                                  <DeliveryDatagrid options={optionsDelivery} />
                                </ReferenceManyField>
                              </TableContainer>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )}
                  </Grid>
                  */}
                  <Grid item xs={12}>
                    {props.record && props.record.id && optionsSegments && (
                      <Accordion
                        disabled={
                          !props.record.segments ||
                          (props.record.segments && props.record.segments.length <= 0)
                        }
                        expanded={accrodinonSegment}
                        onClick={() => setAccrodinonSegment(!accrodinonSegment)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Tratte
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}
                          onClick={(e) => { e.stopPropagation() }}>
                          {
                            accrodinonSegment ?
                              <TableContainer>
                                <ReferenceArrayField reference="segments" source="segments">
                                  <SimpleSegmentDatagrid options={optionsSegments} />
                                </ReferenceArrayField>
                              </TableContainer> :
                              null
                          }
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {props.record && props.record.id && optionsRoutes && (
                      <Accordion
                        disabled={
                          !props.record.routes ||
                          (props.record.routes && props.record.routes.length <= 0)
                        }
                        expanded={accrodinonRoutePlanned}
                        onClick={() => setAccrodinonRoutePlanned(!accrodinonRoutePlanned)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Giri pianificati
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}
                          onClick={(e) => { e.stopPropagation() }}>
                          {accrodinonRoutePlanned ?
                            <TableContainer>
                              <ReferenceArrayField reference="routes" source="routes">
                                <RouteDatagrid options={optionsRoutes} />
                              </ReferenceArrayField>
                            </TableContainer> : null}
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {props.record && props.record.id && optionsDelivery && (
                      <Accordion
                        expanded={accrodinonDeliveryParents}
                        onClick={() => setAccrodinonDeliveryParents(!accrodinonDeliveryParents)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4a-content"
                          id="panel4a-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Missioni figlie
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}
                          onClick={(e) => { e.stopPropagation() }}>
                          {accrodinonDeliveryParents ?
                            <Grid item xs={12}>
                              <TableContainer>
                                <ReferenceManyField
                                  reference="deliveries"
                                  target="parent_delivery"
                                >
                                  <DeliveryDatagrid options={optionsDelivery} />
                                </ReferenceManyField>
                              </TableContainer>
                            </Grid>
                            : null
                          }
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {props.record && props.record.id && optionsNotes && (
                      <Accordion
                        expanded={accrodinonNotes}
                        onClick={() => setAccrodinonNotes(!accrodinonNotes)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Bolle
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}
                          onClick={(e) => { e.stopPropagation() }}>
                          {accrodinonNotes ?
                            <Grid item xs={12}>
                              <TableContainer>
                                <ReferenceManyField
                                  reference="notes"
                                  target="delivery"
                                >
                                  <Datagrid>
                                    <IdentifierField
                                      {...initField("id", optionsNotes)}
                                    />
                                    <DeliveryField
                                      deliverySource={"delivery"}
                                      {...initField("delivery", optionsNotes)}
                                    />
                                    <DateField {...initField("sent", optionsNotes)} />
                                    <FunctionField
                                      label="Bolle"
                                      source="file_info"
                                      render={(tableRecord: any | undefined) => {
                                        if (tableRecord) {
                                          return (
                                            <DownloadNoteFileButton
                                              key={tableRecord.id}
                                              title={tableRecord.file}
                                              noteId={tableRecord.id}
                                              final={tableRecord.final}
                                            />)

                                        }
                                        return null;
                                      }}
                                    />
                                    <CustomFileField
                                      {...initField("file_info", optionsNotes)}
                                      title="name"
                                      src="url"
                                    />

                                    <TextField
                                      {...initField("notes", optionsNotes)}
                                    />
                                  </Datagrid>
                                </ReferenceManyField>
                              </TableContainer>
                            </Grid>
                            : null}
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {props.record && props.record.id && optionsNotes && (
                      <Accordion
                        expanded={accrodinonAttachments}
                        onClick={() => setAccrodinonAttachments(!accrodinonAttachments)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Allegati
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}
                          onClick={(e) => { e.stopPropagation() }}>
                          {
                            accrodinonAttachments ? <>
                              <AddDeliveryAttachmentButton />
                              <Grid item xs={12}>
                                <TableContainer>
                                  <ReferenceManyField
                                    reference="delivery_attachments"
                                    target="delivery"
                                  >
                                    <Datagrid>
                                      <FunctionField
                                        render={(record: any) => (
                                          <DeleteButton
                                            record={record}
                                            resource="delivery_attachments"
                                            redirect={`/deliveries/${record.delivery}`}
                                            mutationMode="pessimistic"
                                          />
                                        )}
                                      />
                                      <IdentifierField
                                        {...initField("id", optionsNotes)}
                                      />

                                      <CustomFileField
                                        {...initField("file_info", optionsNotes)}
                                        title="name"
                                        src="url"
                                      />

                                      <TextField
                                        {...initField("notes", optionsNotes)}
                                      />
                                    </Datagrid>
                                  </ReferenceManyField>
                                </TableContainer>
                              </Grid>
                            </> : null
                          }
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {props.record && props.record.id && optionsDeliveryItems && (
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panela-content"
                          id="panela-header"
                        >
                          <Typography variant="h6" gutterBottom>
                            Righe bolla
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}>
                          <AddDeliveryNoteRowForm />
                          <TableContainer>
                            <ReferenceArrayField
                              label="Righe bolla"
                              reference="delivery-note-rows"
                              source="deliverynoterow_set"
                            >
                              <DeliveryNoteRowDatagrid
                                options={optionsDeliveryNoteRows}
                                updateRows={() => {
                                  refresh();
                                }}
                              />
                            </ReferenceArrayField>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                </Grid>

              </Box>
            </CardContent>
            <CustomToolbar
              canDelete={getUser().is_admin === true ? props.canDelete : false}
              canEdit={props.canEdit}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card >
      )}
    />
  ) : null;
};
