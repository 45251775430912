// botton crea riconsegna
import { Dialog, DialogContent, DialogActions, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { useState } from "react";
import {
  useRecordContext,
  Button,
  FormWithRedirect,
  SaveButton,
  useNotify,
  useRedirect,
  required,
  FormDataConsumer,
  BooleanInput,
  TextInput,
  DateInput,
  DateTimeInput,
  useDataProvider,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptions } from "../../shared/hooks/useOptions";
import { generateError, initInput } from "../../shared/utils";
import { client } from "../../dataProvider";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import AddIcon from '@material-ui/icons/Add';
import { DeliveryType, FormProps, Subject } from "../../shared/types";
import TodayIcon from '@material-ui/icons/Today';

export const CreatePlaceholderDeliveryButton = () => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [checkedReceiver, setCheckedReceiver] = useState(true);

  const updateReceiver = (
    value: number,
    form: FormProps
  ) => {
    dataProvider
      .getOne<Subject>("subjects", {
        id: value,
      })
      .then(({ data: subject }) => {
        if (subject.starting_point_id) {
          if (subject.starting_point?.type.includes("destinatario")) {
            form.change("receiver", subject.starting_point_id);
          }
        } else {
          if (subject?.type.includes("destinatario")) {
            form.change("receiver", subject.id);
          }
        }
      });
  };

  const setToday = (
    value: Date,
    field: string,
    form: FormProps
  ) => {
    form.change(field, value);
  };

  const onClick = (values: any) => {
    setLoading(true);
    client(`remittances/${record.id}/create_placeholder_delivery`, {
      method: "POST",
      data: values,
    })
      .then((data) => {
        window.open("/deliveries/" + data.delivery, "_blank");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        generateError(error, notify);
      });
  };
  const [loading, setLoading] = useState(false);
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");

  return record && options && optionsTariffs ? (
    <>

      <Button
        disabled={!!!record?.client}
        onClick={(e) => {
          e.stopPropagation();
          setTrue();
        }}
        label={"Crea segnaposto"}
      >
        <AddIcon />
      </Button>


      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{ delivery_type: DeliveryType.PLACEHOLDER }}
          save={onClick}
          render={({ handleSubmitWithRedirect, saving, form }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <BooleanInput
                      source="exclude_first_segment"
                      label="Escludi prima tratta"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BooleanInput
                      source="exclude_last_segment"
                      label="Escludi ultima tratta"
                      fullWidth
                      defaultValue={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BooleanInput
                      source="exclude_all_segment"
                      label="Escludi tutte le tratte"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ReferenceInputSubject
                      {...initInput(
                        "client.id",
                        true
                      )}
                      required={true}
                      filter={{ type: "client" }}
                      label="Cliente"
                      defaultValue={record.client.id}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInputSubject
                      {...initInput(
                        "sender",
                        true
                      )}
                      required={true}
                      label="Mittente"
                      defaultValue={710}
                    />

                  </Grid>

                  <Grid item xs={12}>
                    <ReferenceInputSubject
                      {...initInput(
                        "starting_point_on_delivery",
                        true
                      )}
                      label="Punto partenza rimessa"
                      defaultValue={710}
                      validate={required()}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ReferenceInputSubject
                      {...initInput(
                        "transit_point",
                        true
                      )}
                      label="Transit Point"
                      defaultValue={710}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <ReferenceInputSubject
                      {...initInput(
                        "receiver",
                        true
                      )}
                      filter={{ type: "receiver" }}
                      validate={required()}
                      required
                      label="Destinatario"
                      defaultValue={record.client.starting_point ? record.client.starting_point : record.client.id}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormDataConsumer>
                      {({ formData }) => (
                        <Grid item>
                          <FormControlLabel
                            style={{ margin: "0px" }}
                            control={
                              <Checkbox
                                disabled={
                                  initInput(
                                    "receiver_check",
                                    options,
                                    true
                                  ).disabled
                                }
                                checked={checkedReceiver}
                                onChange={(e, value) => {
                                  setCheckedReceiver(value);
                                  if (value) {
                                    const client =
                                      form.getFieldState("client.id");
                                    if (client && client.value) {
                                      updateReceiver(client.value, form);
                                    }
                                  }
                                  else {
                                    form.change("receiver", null);
                                  }
                                }}
                                inputProps={{ tabIndex: -1 }}
                              />
                            }
                            label={"Magaz. cliente"}
                          />
                        </Grid>
                      )}
                    </FormDataConsumer>
                  </Grid>
                  <Grid item xs={10}>
                    <DateTimeInput
                      {...initInput(
                        "delivery_date",
                        options,
                        true
                      )}
                      inputProps={{ tabIndex: -1 }}
                      validate={required()}
                      required
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <FormDataConsumer>
                      {({ formData }) => (
                        <FormControlLabel
                          style={{ margin: "10px" }}
                          control={
                            <Button onClick={() => {
                              setToday(new Date(), "delivery_date", form)
                            }}>
                              <TodayIcon fontSize="large" />
                            </Button>
                          }
                          label={"Oggi"}
                        />
                      )}
                    </FormDataConsumer>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      source="document_number"
                      label="Nuovo numero documento"
                      defaultValue={"RI." + record.remittance_sequential_number}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput
                      {...initInput(
                        "document_date",
                        true
                      )}
                      label="Data documneto"
                      defaultValue={record.remittance_date}
                    />
                  </Grid>
                </Grid>


              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};

