import React from "react";
import { LayoutProps } from "react-admin";
import { CustomMenu } from "./CustomMenu";
import { Layout } from "./Layout";
import { CustomSidebar } from "./CustomSidebar";
import { CustomAppBar } from "./CustomAppBar";
import { CustomMenuTree } from "./CustomMenuTree";

export const CustomLayout = (props: LayoutProps) => {
  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      menu={CustomMenuTree}
      sidebar={CustomSidebar}
    />
  );
};
