import { Card } from "@material-ui/core";
import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { BadgeForm } from "./BadgeForm";

export const BadgeEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <BadgeForm />
    </DefaultEdit>
  );
};
