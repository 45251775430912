import { BooleanInput, Button, ButtonProps, DateInput, DateTimeInput, FormDataConsumer, FormWithRedirect, ReferenceInput, required, SaveButton, SelectInput, TextInput, useListContext, useNotify } from "react-admin";
import DescriptionIcon from "@material-ui/icons/Description";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { generateError, initInput } from "../../shared/utils";
import { useToggler } from "../../shared/hooks/useToggler";
import { useOptions } from "../../shared/hooks/useOptions";
import IconCancel from "@material-ui/icons/Cancel";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DeliveryType } from "../../shared/types";

export const ProgrammedStockEndBulkButton = (props: ButtonProps) => {
  const notify = useNotify();

  const { value, setTrue, setFalse } = useToggler();
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const onClick = (values: any) => {
    setLoading(true);

    client(`deliveries/set_delivery_programmed_stock_end`, {
      method: "POST",
      data: {
        ids: selectedIds,
        ...values
      }
    }).then((response) => {
      notify("Sblocco missioni programmato");
      setFalse();
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      });
    refetch();

  };


  return (
    <>
      <Button
        {...props}
        disabled={loading || selectedIds.length <= 0}
        label="Sblocco programmato giacenze"
        title="Imposta data di sblocco programmata per giacenze/sospese"
        onClick={setTrue}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <FileCopyIcon />}
      </Button>

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{}}
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <DateTimeInput
                  {...initInput(
                    "date",
                    true
                  )}
                  label="Data sblocco"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
