import { Grid, makeStyles } from "@material-ui/core";
import {
  DeleteButton,
  SaveButton,
  Toolbar,
  ToolbarProps,
  usePermissions,
} from "react-admin";
import { PERMISSIONS } from "../constants";
import { getRealResource } from "../dataProvider";

const valueOrDefault = (value: any, defaultValue: any) =>
  typeof value === "undefined" ? defaultValue : value;
const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
    },
    desktopToolbar: {
      marginTop: theme.spacing(2),
    },
    mobileToolbar: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "16px",
      width: "100%",
      boxSizing: "border-box",
      flexShrink: 0,
      zIndex: 2,
    },
    defaultToolbar: {
      height: "50px",
      position: "fixed",
      bottom: "10px",
      zIndex: 1000,
      width: "80%",
      backgroundColor: "white",
      //border: "2px solid red"
    },
    spacer: {
      [theme.breakpoints.down("xs")]: {
        height: "5em",
      },
    },
  }),
  { name: "RaToolbar" }
);
export const CustomToolbar = (
  props: ToolbarProps & { canDelete?: boolean; canEdit?: boolean, hide_3?: boolean }
) => {
  const { permissions } = usePermissions();
  const { canDelete, canEdit, ...rest } = props;
  const resource = props.resource!;
  const disabled = !valueOrDefault(
    props.alwaysEnableSaveButton,
    !props.pristine && !props.validating
  );
  const hide_3 = props.hide_3 || false
  const classes = useStyles(props);
  const permission = PERMISSIONS[getRealResource(resource)].edit;

  return (
    <Toolbar className={classes.defaultToolbar} {...rest}>
      <Grid container>
        <Grid item xs={2}>
          {permissions &&
            permissions.includes(permission) &&
            (props.record && props.record.id ? canEdit : true) && (
              <SaveButton
                handleSubmitWithRedirect={
                  props.handleSubmitWithRedirect || props.handleSubmit
                }
                disabled={disabled}
                invalid={props.invalid}
                redirect={props.redirect}
                saving={props.saving || props.validating}
                submitOnEnter={props.submitOnEnter}
              />
            )}
        </Grid>
        {permissions &&
          permissions.includes(permission) &&
          props.handleSubmitWithRedirect &&
          props.record?.id && (

            <Grid item xs={3}>
              <SaveButton
                handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                disabled={disabled}
                invalid={props.invalid}
                redirect={`create`}
                saving={props.saving || props.validating}
                label="Salva e aggiungi nuovo"
                submitOnEnter={false}
              />
            </Grid>
          )}

        {permissions &&
          permissions.includes(permission) &&
          props.handleSubmitWithRedirect &&
          !props.record?.id && (
            <Grid item xs={3}>
              <SaveButton
                handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                disabled={disabled}
                invalid={props.invalid}
                redirect={false}
                saving={props.saving || props.validating}
                label="Salva e aggiungi nuovo"
                submitOnEnter={false}
              />
            </Grid>
          )}
        <Grid item xs={3}>
          {permissions &&
            permissions.includes(permission) &&
            props.handleSubmitWithRedirect && !hide_3 && (
              <SaveButton
                handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                disabled={disabled}
                invalid={props.invalid}
                redirect={(basePath, id, data) => {
                  if (resource === "deliveries") {
                    data["main_delivery"] = null
                    data["deliveryitem_set"] = null
                    data["document_number"] = null
                    data["receiver"] = null
                    data["delivery_date"] = data["delivery_date"].split("+")[0]
                  }
                  return `/${resource}/create/?source=${JSON.stringify({
                    ...data,
                    id: undefined,
                  })}`
                }

                }
                saving={props.saving || props.validating}
                label="Salva e copia dati in nuovo"
                submitOnEnter={false}
              />
            )}
        </Grid>

        <Grid item xs={2}>
          {permissions && permissions.includes(permission) && canDelete && (
            <DeleteButton
              basePath={props.basePath}
              record={props.record}
              resource={resource}
              undoable={props.undoable}
              mutationMode={props.mutationMode}
            />
          )}
        </Grid>

      </Grid>


    </Toolbar>
  );
};
