import {
  Datagrid,
  TextField,
  DateField,
  NumberField,
  SelectField,
  FunctionField,
  Record,
  BooleanField,
  Button,
  useNotify,
  useListContext,
  RecordContext,
  RecordContextProvider,
} from "react-admin";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { IdentifierField } from "../../components/IdentifierField";
import { LocationField } from "../../components/LocationField";
import { RouteField } from "../../components/RouteField";
import { SubjectField } from "../../components/SubjectField";
import { useOptions } from "../../shared/hooks/useOptions";
import { DatagridPropsWithOptions, DeliveryType } from "../../shared/types";
import { generateError, initField } from "../../shared/utils";
import { CloneDeliveryButton } from "./CloneDeliveryButton";
import { PrintDocumentsButton } from "./PrintDocuments";
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { DeliveryInvoiceStatusField } from "../../components/DeliveryInvoiceStatusField";
import ErrorIcon from '@material-ui/icons/Error';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useEffect, useState } from "react";
import { DeliveryField } from "../../components/DeliveryField";
import { DeliveryTrackingNumberField } from "../../components/DeliveryTrackingNumberField";
import { DeliveryTrackingField } from "../../components/DeliveryTrackingField";
import { Badge, CircularProgress, Dialog, Modal, Typography } from "@material-ui/core";
import { de, ta } from "date-fns/locale";
import { DeliveryGroupField } from "../../components/DeliveryGroupField";
import { DownloadFileButton } from "../../components/DownloadFileButton";
import { client } from "../../dataProvider";
import { useToggler } from "../../shared/hooks/useToggler";
import ClearIcon from '@material-ui/icons/Clear';

let green = "rgb(76, 175, 80)";
let yellow = "rgb(255, 152, 0)";
let red = "#f44336";

export const DeliveryDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");
  const notify = useNotify();

  const { value, setTrue, setFalse } = useToggler();
  const [loading, setLoading] = useState(false);
  const { refetch } = useListContext();
  return optionsDeliveryStates ? (
    <Datagrid {...rest}>
      {/*<IdentifierField {...initField("id", options)} /> */}
      <DeliveryField {...initField("id", options)} deliverySource="id" noTitle={true} />
      <FunctionField
        sortable={false}
        label={
          <div style={{
            borderRadius: "50%",
            width: "15px",
            height: "15px",
            padding: "3px",
            background: red,
            //border: "1px solid #000",
            color: "#000",
            textAlign: "center",
            font: "14px Arial, sans-serif"
          }}
          >
            <b>N</b>
          </div>
        }
        source="hierarchy"
        render={(tableRecord: Record | undefined) => {
          if (!tableRecord) {
            return null;
          }
          let n = 1;
          let color = green;
          let title = "Ultima missione";
          if (tableRecord?.hierarchy?.length) {
            n = tableRecord.hierarchy.length;
          }
          if (n === 2) {
            if (tableRecord?.hierarchy[0] === tableRecord.id) {
              color = red;
              title = "Prima missione";
            }
          }
          if (n > 2) {
            if (tableRecord?.hierarchy[0] === tableRecord.id) {
              color = red;
              title = "Prima missione";
            }
            else {
              if (tableRecord?.hierarchy[tableRecord?.hierarchy.length - 1] === tableRecord.id) {
                color = green;
                title = "Ultima missione";
              }
              else {
                color = yellow;
                title = "Centrale missione";
              }
            }

          }


          return (
            <div style={{
              borderRadius: "50%",
              width: "15px",
              height: "15px",
              padding: "3px",
              background: color,
              //border: "1px solid #000",
              color: "#000",
              textAlign: "center",
              font: "14px Arial, sans-serif",
              cursor: "pointer"
            }}
              onClick={(e: any) => {
                e.preventDefault()
                window.open(`/deliveries?displayedFilters={}&filter={"main_delivery"%3A"${tableRecord.hierarchy[0]}"}`, "_blank");
              }}
              title={title}
            >
              <b>{n}</b>
            </div>
          );
          return null;
        }}
      />
      <FunctionField
        label="Bolle"
        source="note_set"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.note_set) {
            return tableRecord.note_set.map((note: any) => (
              <DownloadNoteFileButton
                key={note.id}
                title={note.file}
                noteId={note.id}
                final={note.final}
              />
            ));
          }
          return null;
        }}
      />
      <FunctionField
        label="Gruppo"
        source="delivery_group"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.delivery_group) {

            return (
              <DeliveryGroupField key={tableRecord.delivery_group.file} record={{ id: tableRecord.delivery_group.id }}
                hasFile={tableRecord.delivery_group.file ? true : false} deliveryGroupSource="id" />
            )

          }
          return null;
        }}
        sortable={false}
      />
      <BooleanField {...initField("internal_notes", options)} label={"Interna"} />
      <DeliveryTypeField source="delivery_type" options={options} label={"Tipo"} />
      <TextField {...initField("document_number", options)} />
      <DateField {...initField("document_date", options)} label={"Data doc."} />
      {
        //<DateField {...initField("delivery_date", options)} label={"Data miss."} />
      }
      <DateField {...initField("delivery_last_status__date", options)} label={"Data stato"} />
      <SubjectField {...initField("client_object", options)}
        sortBy="client__business_name" />
      <SubjectField {...initField("sender_object", options)}
        sortBy="sender__business_name" />
      <FunctionField
        label="P. Partenza consegna"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord) {
            if ((tableRecord.delivery_type === DeliveryType.NORMAL || tableRecord.delivery_type === DeliveryType.RETURN || tableRecord.delivery_type === DeliveryType.PLACEHOLDER) && tableRecord.starting_point_on_delivery_object) {
              return <SubjectField {...initField("starting_point_on_delivery_object", options)} />
            }
            else {
              if ((tableRecord.delivery_type === DeliveryType.BACK || tableRecord.delivery_type === DeliveryType.WITHDRAW) && tableRecord.receiver_object) {
                return <SubjectField {...initField("receiver_object", options)} />
              }
            }
          }
          return null;
        }}
        sortBy="starting_point_on_delivery__business_name"
      />
      <FunctionField
        label="Destinazione"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord) {
            if ((tableRecord.delivery_type === DeliveryType.NORMAL || tableRecord.delivery_type === DeliveryType.RETURN || tableRecord.delivery_type === DeliveryType.PLACEHOLDER) && tableRecord.receiver_object) {
              return <SubjectField {...initField("receiver_object", options)} />
            }
            else {
              if ((tableRecord.delivery_type === DeliveryType.BACK || tableRecord.delivery_type === DeliveryType.WITHDRAW) && tableRecord.starting_point_on_delivery_object) {
                return <SubjectField {...initField("starting_point_on_delivery_object", options)} />
              }
            }
          }
          return null;
        }}
        sortBy="receiver__business_name"
      />
      <LocationField {...initField("locality", options)} label="Località" sortable={false} />
      <NumberField {...initField("weight", options)} />
      <NumberField {...initField("packages_number", options)} />
      <NumberField {...initField("cachet", options)} label="Contrass." sortable={false} />
      <NumberField {...initField("import_file", options)} />
      <SelectField
        {...initField("status", optionsDeliveryStates, "array")}
        choices={optionsDeliveryStates.status.choices}
        sortBy="delivery_last_status__status"
      />
      <FunctionField label="Sbl. giac."
        source="stock_end"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.stock_end) {

            return <>
              <RecordContextProvider value={tableRecord.stock_end}>
                <DateField source="date" showTime title={tableRecord.stock_end.user} />
                <Button label="" onClick={() => {
                  setLoading(true)
                  client(`deliveries/delete_delivery_programmed_stock_end`, {
                    method: "POST",
                    data: {
                      id: tableRecord.stock_end.id
                    }
                  }).then((response) => {
                    notify("Eliminato sblocco missioni programmato");
                    setFalse();
                    refetch();
                  })
                    .catch((error) => {
                      generateError(error, notify);
                    })
                    .finally(() => {
                      setLoading(false);
                    });

                }}>{loading ? <CircularProgress size={18} thickness={2} /> : <ClearIcon />}</Button>
              </RecordContextProvider>
            </>
          }
          return null;
        }}
        sortable={false}
      />

      <BooleanField {...initField("partial_segment", options)} label="Tratta parz." />
      <TextField {...initField("status_reason", options)} label="Motivo" sortBy="delivery_last_status__reason__description" />
      <BooleanField {...initField("partial", options)} TrueIcon={CheckBox} FalseIcon={CheckBoxOutlineBlank} label={"Parziale"} />
      <BooleanField {...initField("price_warning", options)} TrueIcon={ErrorIcon} FalseIcon={ThumbUpIcon} label={"Alert €"} />,
      <FunctionField
        label="Giri"
        source="routes"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.note_set) {
            return tableRecord.routes.map((note: any) => (
              <>
                <RouteField key={note} record={{ id: note }}
                  hasFile={tableRecord.route_files[note] ? true : false} routeSource="id" />
              </>
            ));
          }
          return null;
        }}
        sortable={false}
      />
      <PrintDocumentsButton label="Stampa" />
      {/*
      <DeliveryTrackingNumberField
        {...initField("tracking_number", options)} deliverySource="tracking_number"
        label={"Tracking"}
      />
      */}
      <DeliveryTrackingField
        {...initField("tracking_number", options)} deliverySource="tracking_number"
        label={"Tracking"}
      />
      <CloneDeliveryButton buttonLabel="Crea riconsegna" type={DeliveryType.NORMAL} />
      <DeliveryInvoiceStatusField source="invoice_status" options={options} label={"Stato addebito/fattura"} />
    </Datagrid >
  ) : null;
};
