import { Card } from "@material-ui/core";
import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { PresenceForm } from "./PresenceForm";

export const PresenceEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <PresenceForm />
    </DefaultEdit>
  );
};
