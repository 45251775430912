import { Card, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, FormLabel, Grid, InputBase, Table, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { getUser } from "../../authProvider";
import { ChangePassword } from "../../Users";
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { alpha, makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { TransitionProps } from '@material-ui/core/transitions';
import { client } from "../../dataProvider";
import { AutocompleteInput, BooleanInput, Button, ButtonProps, FormWithRedirect, ReferenceInput, SaveButton, TextInput, useNotify, useQuery, useRefresh } from "react-admin";
import { useEffect, useMemo, useState } from "react";
import { ReferenceInputChartAccounts } from "../../components/reference-inputs/ReferenceInputChartAccounts";
import { formatThousandsNumber, initInput } from "../../shared/utils";
import { useToggler } from "../../shared/hooks/useToggler";
import { useOptions } from "../../shared/hooks/useOptions";
import { AxiosResponse } from "axios";
import IconCancel from "@material-ui/icons/Cancel";
import AddIcon from '@material-ui/icons/Add';
import { func } from "prop-types";
import { set } from "lodash";
import { ReferenceInputSite } from "../../components/reference-inputs/ReferenceInputSite";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox } from "@material-ui/icons";


const cellPadding = 0;
const leftMargin = 50;
const tableWidth = 450 + 500 + 150 + 150 + 200;
const table_last_column_width = window.innerWidth - tableWidth - 50;
const tableFinalWidth = tableWidth + table_last_column_width;

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {

  return (
    <div>
      <Collapse {...props} />
    </div>
  );
}


const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 70,
      paddingLeft: 50,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    label: {
      fontSize: "1.2em",
      fontWeight: "bold"
    }
  }),
)((props: TreeItemProps) => <
  TreeItem {...props} TransitionComponent={TransitionComponent}
/>);

const useStyles = makeStyles(
  createStyles({
    root: {
      height: "80%",
      flexGrow: 1,
      maxWidth: "80%",
    },
  }),
);


export const ChartAccountsCreateButton = (props: ButtonProps & { main_id: any }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { value, setTrue, setFalse } = useToggler();
  const { data: options } = useOptions("chartaccounts", "GET");
  const handleSubmit = async (values: any) => {
    setLoading(true);
    let res: AxiosResponse<any, any>;
    try {
      res = await client(`chartaccounts`, {
        method: "POST",
        data: {
          main: values.main,
          name: values.name,
          active: values.active
        }
      });
    } catch (err: any) {
      console.log(err)
      notify("Attenzione error di creazione", "error");
      return;
    } finally {
      setLoading(false);
      setFalse();
    }

    if (res !== null) {
      const data = res.data; // or res.blob() if using blob responses

      try {
        window.location.reload();
      }
      catch (e) {

      }
    }



  };

  return options ? (
    <>
      <Button
        {...props}
        disabled={loading}
        label="Crea categoria"
        title="Nuova categoria piano dei conti"
        onClick={setTrue}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <AddIcon />}
      </Button>
      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, form, saving }) => (
            <>
              <DialogContent>
                <CardContent>
                  <Grid
                    container
                    justifyContent="flex-start"
                    direction={"row"}
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <ReferenceInputChartAccounts
                        {...initInput(
                          "main",
                          options,
                          true
                        )}
                        filter={{}}
                        disabled={true}
                      />
                    </Grid>

                    {
                      form.change("main", props.main_id)
                    }

                    <Grid item xs={6}>
                      <TextInput
                        {...initInput(
                          "name",
                          options,
                          true
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BooleanInput
                        {...initInput(
                          "active",
                          options,
                          true
                        )}
                        defaultValue={true}
                      />
                    </Grid>

                  </Grid>
                </CardContent>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};




const RecoursiveGridTree = (props: any) => {

  const setSelectedChartAccounts = props.setSelectedChartAccounts;
  const selectedChartAccounts = props.selectedChartAccounts;

  const level = props.level !== undefined ? props.level + 10 : 0;

  return (
    <>
      {
        props.chartAccountsTree ? props.chartAccountsTree.map((ca: any) => {
          return (
            <Grid item xs={12} style={
              {
                opacity: ca.active ? 1 : 0.5,
                marginLeft: ca.main === null ? 0 : leftMargin,
                borderLeft: `1px dashed ${alpha("#000000", 0.4)}`,
                borderTop: ca.main === null ? `2px solid ${alpha("#000000", 0.8)}` : "",
                padding: 0,
                backgroundColor: alpha("#FFFFFF", 1),
              }}>
              <Table style={{
                width: (tableFinalWidth - (level / 10 * leftMargin)),
                backgroundColor: alpha("#00FF55", (50 - level * 2.5) / 100),
              }}>
                <TableRow>
                  <TableCell style={{
                    padding: cellPadding,
                    width: 50,
                  }}>
                    {ca.sub_accounts.length === 0 ? <Checkbox
                      checked={selectedChartAccounts[ca.id] ? true : false}
                      onChange={() => { setSelectedChartAccounts({ ...selectedChartAccounts, [ca.id]: !selectedChartAccounts[ca.id] }) }}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> : null}
                  </TableCell>
                  <TableCell style={{
                    padding: cellPadding,
                    width: (450 - (level / 10 * (leftMargin))),
                  }}>
                    <Button
                      title={"ID: " + ca.id}
                      onClick={() => {
                        window.location.href = "/chartaccounts/" + ca.id + "/";
                      }}><Typography>{ca.code}</Typography>
                    </Button>
                  </TableCell>
                  <TableCell style={{
                    padding: cellPadding,
                    width: 500,
                  }}><b style={{ fontSize: 15 }}>{ca.name}</b></TableCell>
                  <TableCell style={{ padding: cellPadding, width: 150, }}><b style={{ fontSize: 15 }}>{formatThousandsNumber(ca.amount)}€</b></TableCell>
                  <TableCell style={{
                    padding: cellPadding,
                    width: 150,
                  }}><b style={{ fontSize: 15 }}>{formatThousandsNumber(ca.amount_total)}€</b></TableCell>
                  <TableCell style={{
                    padding: cellPadding,
                    width: 200 + table_last_column_width,
                  }}>
                    <ChartAccountsCreateButton main_id={ca.id}></ChartAccountsCreateButton>
                  </TableCell>
                </TableRow>
              </Table>
              <RecoursiveGridTree chartAccountsTree={ca.sub_accounts} level={level}
                setSelectedChartAccounts={setSelectedChartAccounts}
                selectedChartAccounts={selectedChartAccounts}
              />
            </Grid >
          );
        }
        ) : null
      }
    </>
  )
}





export const ChartAccountsTreePage = (props: any) => {
  const [chartAccountsList, setChartAccountsList] = useState<any>([]);

  const [initChartAccountsTree, setInitChartAccountsTree] = useState<any>([]);
  const [chartAccountsTree, setChartAccountsTree] = useState<any>([]);
  const [selectedChartAccounts, setSelectedChartAccounts] = useState<any>({});
  const [mainStructure, setMainStructure] = useState<string>("");

  const [dateFrom, setDateFrom] = useState(new URL(window.location.href).searchParams.get("date_from"));
  const [dateTo, setDateTo] = useState(new URL(window.location.href).searchParams.get("date_to"));
  const [site, setSite] = useState(new URL(window.location.href).searchParams.get("site"));

  const [total, setTotal] = useState(0.0);
  const [totalInit, setTotalInit] = useState(0.0);
  const [totalSelected, setTotalSelected] = useState(0.0);

  const [siteList, setSiteList] = useState<any>([]);

  useMemo(() => {
    let query = "";
    let date_from = new URL(window.location.href).searchParams.get("date_from");
    if (date_from) {
      if (query !== "") {
        query += "&";
      }
      else {
        query += "?";
      }
      query += "date_from=" + date_from;
    }
    let date_to = new URL(window.location.href).searchParams.get("date_to");
    if (date_to) {
      if (query !== "") {
        query += "&";
      }
      else {
        query += "?";
      }
      query += "date_to=" + date_to;
    }
    let site_id = new URL(window.location.href).searchParams.get("site");
    if (site_id) {
      if (query !== "") {
        query += "&";
      }
      else {
        query += "?";
      }
      query += "site=" + site_id;
    }
    client("chartaccountstree" + query,
      {
        method: "GET",
      }
    ).then((res: any) => {
      setChartAccountsList(getLastsChartAccounts(res.results))
      setInitChartAccountsTree(res.results)
      setChartAccountsTree(res.results)
      setMainStructure(createMainStructure(res.results));
      setSelectedChartAccounts(getLastsChartAccountsIds(res.results));
      setTotalInit(res.total);
      setTotal(res.total);
    });

    client("sites?order_by=business_name",
      {
        method: "GET",
      }
    ).then((res: any) => {
      setSiteList(res.results)
    });
  }, []);

  const classes = useStyles();

  function createMainStructure(elements: any, result: any = {}) {
    elements.forEach((element: any) => {
      result[element.id] = element.main;
      if (element.sub_accounts.length > 0) {
        result = createMainStructure(element.sub_accounts, result);
      }
    });
    return result;
  }

  function getLastsChartAccounts(elements: any, result: any = {}) {
    elements.forEach((element: any) => {
      if (element.sub_accounts.length > 0) {
        result = getLastsChartAccounts(element.sub_accounts, result);
      }
      else {
        result[element.id] = element;
      }
    });
    return result;
  }

  function getLastsChartAccountsIds(elements: any, result: any = {}) {
    elements.forEach((element: any) => {
      if (element.sub_accounts.length > 0) {
        result = getLastsChartAccountsIds(element.sub_accounts, result);
      }
      else {
        result[element.id] = false;
      }
    });
    return result;
  }

  function recreateTreeStructure(elements: any, selected: [number]) {
    let result: any = [];

    elements.forEach((element: any) => {
      let found = false
      let element_temp = JSON.parse(JSON.stringify(element));
      if (selected.includes(element.id)) {
        found = true;
        element_temp.sub_accounts = [];
      }
      if (found && element.sub_accounts.length > 0) {
        element_temp.sub_accounts = recreateTreeStructure(element.sub_accounts, selected);
      }
      if (found) {
        result.push(element_temp);
      }
    });
    return result;
  }


  function searchInTree(elements: any, search: string) {
    let result: any = [];
    elements.forEach((element: any) => {
      if (element.code === search || element.name.toLowerCase().includes(search.toLowerCase())) {
        if (!result.includes(element.id))
          result.push(element.id);
        let e_temp = element.id;
        while (e_temp) {
          if (!result.includes(e_temp))
            result.push(e_temp);
          e_temp = mainStructure[e_temp];
        }
      }
      if (element.sub_accounts.length > 0) {
        result = result.concat(searchInTree(element.sub_accounts, search));
      }
    });

    result = [...new Set(result)];

    return result

  }

  function getTotals(elements: any) {
    let result = 0;
    elements.forEach((element: any) => {
      result += element.amount;
      if (element.sub_accounts.length > 0) {
        result += getTotals(element.sub_accounts);
      }
    });
    return result;
  }

  function getMainTotals(elements: any) {
    let result = 0;
    elements.forEach((element: any) => {
      result += element.amount_total;
    });
    return result;
  }

  function reloadPageWithDate() {
    const url = new URL(window.location.href);
    const search_params = url.searchParams;
    search_params.set("date_from", dateFrom ? dateFrom : first_gennary);
    search_params.set("date_to", dateTo ? dateTo : "");
    search_params.set("site", site ? site : "");
    window.location.href = url.pathname + url.search;
  }

  const first_gennary = new Date(new Date().getFullYear() + "-01-01").toISOString().split('T')[0];

  if (!new URL(window.location.href).searchParams.get("date_from")) {
    reloadPageWithDate()
  }

  useEffect(() => {
    let tot = 0.0;
    for (let key in selectedChartAccounts) {
      if (selectedChartAccounts[key]) {
        for (let key2 in chartAccountsList) {
          if (chartAccountsList[key2].id == key) {
            tot += chartAccountsList[key2].amount;
          }
        }
      }
    }
    setTotalSelected(tot);
  }, [selectedChartAccounts]);

  return (

    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
    >
      <Grid item container xs={12} style={{
        position: "fixed",
        top: 0,
        paddingTop: 55,
        paddingBottom: 10,
        backgroundColor: "#FFFFFF",
        zIndex: 1,
        borderBottom: "1px solid #000000",
      }}>
        <Grid item xs={3}>
          <Typography variant="h3" align="left">Piano dei conti</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4" align="center">Totale visualizzato: <br />{formatThousandsNumber(Math.round(total * 100) / 100)}€</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" align="center">Totale sel: <br />{formatThousandsNumber(Math.round(totalSelected * 100) / 100)}€</Typography>
        </Grid>


        <Grid item xs={3}>
          <FormLabel>Da</FormLabel>
          <TextField
            type="date"
            onChange={(e) => {
              const search = e.target.value;
              setDateFrom(search);
            }}
            defaultValue={
              new URL(window.location.href).searchParams.get("date_from")
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormLabel>A</FormLabel>
          <TextField
            type="date"
            onChange={(e) => {
              const search = e.target.value;
              setDateTo(search);
            }}
            defaultValue={
              new URL(window.location.href).searchParams.get("date_to")
            }
          />
        </Grid>

        <Grid item xs={3}>
          {siteList ?
            <Autocomplete
              id="site-autocomplete"
              options={siteList}
              getOptionLabel={(s: any) => s.id + " - " + s.business_name}
              style={{ width: 350 }}
              renderInput={(params) => <TextField {...params} label="Sede" />}
              onChange={(e, value) => {
                if (value)
                  setSite(value.id);
                else
                  setSite("");
              }}
              value={
                site && siteList.filter(function (el: any) { return el.id == site; }).length > 0 ? siteList.filter(function (el: any) { return el.id == site; })[0] : null}
            /> : null
          }
        </Grid>


        <Grid item xs={3}>
          <Button onClick={reloadPageWithDate}><Typography>Cerca</Typography></Button>

        </Grid>

        <Grid item xs={6}>
          <ChartAccountsCreateButton main_id={null}></ChartAccountsCreateButton>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Cerca nella tabella"
            onChange={(e) => {
              const search = e.target.value;

              if (search === "") {
                setChartAccountsTree(initChartAccountsTree);
                setTotal(totalInit);
              } else {
                let tempTreeStructrue = recreateTreeStructure
                  (initChartAccountsTree,
                    searchInTree(
                      initChartAccountsTree, search)
                  )

                setTotal(getMainTotals(tempTreeStructrue));
                setChartAccountsTree(
                  tempTreeStructrue
                );
              }
            }} />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "210px" }}>
        <Table style={{ width: tableFinalWidth }}>
          <TableHead>
            <TableCell style={{
              padding: 0,
              width: 50,
            }}>
              <b style={{ fontSize: 15 }}></b>
            </TableCell>
            <TableCell style={{
              padding: 0,
              width: 450,
            }}>
              <b style={{ fontSize: 15 }}>Codice</b>
            </TableCell>
            <TableCell style={{
              padding: cellPadding,
              width: 500,
            }}><b style={{ fontSize: 15 }}>Nome</b></TableCell>
            <TableCell style={{ padding: cellPadding, width: 150, }}><b style={{ fontSize: 15 }}>Parziale</b></TableCell>
            <TableCell style={{
              padding: cellPadding,
              width: 150,
            }}><b style={{ fontSize: 15 }}>Totale sezione</b></TableCell>
            <TableCell style={{
              padding: cellPadding,
              width: 200 + table_last_column_width,
            }}>
              <b style={{ fontSize: 15 }}>Crea nuova categoria</b>
            </TableCell>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <RecoursiveGridTree
          chartAccountsTree={chartAccountsTree}
          selectedChartAccounts={selectedChartAccounts}
          setSelectedChartAccounts={setSelectedChartAccounts}
        />
      </Grid>



    </Grid>


  );
};
