import { AutocompleteInput, BooleanInput, BulkDeleteButton, DateInput, ListProps, NullableBooleanInput, ReferenceInput, SelectArrayInput, SelectInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { ReferenceInputItem } from "../../components/reference-inputs/ReferenceInputItem";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";

import { TariffDatagrid } from "./TariffDatagrid";
import { TariffDuplicationBulkButton } from "./TariffDuplicationBulkButton";
import { TariffExcelBulkButton } from "./TariffExcelBulkButton";

export const TariffList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  const filters = options
    ? [
      <SelectInput
        {...initField("tariff_type", options, "array")}
        choices={options.tariff_type.choices}
        alwaysOn
      />,
      <NullableBooleanInput
        {...initField("last_tariff", options)}
        label="Ultima tariffa"
        alwaysOn
      />,
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
        alwaysOn
      />,
      <NullableBooleanInput
        {...initField("no_price", options)}
        label="Tariffa nulle"
      />,
      <ReferenceInputSubject
        {...initField("supplier", options)}
        filter={{ type: "supplier" }}
      />,

      <DateInput {...initField("start_date", options)} />,
      <ReferenceInputItem
        {...initField("item", options)}
      />,
      <SelectArrayInput
        {...initField("charge_types", options, "array")}
        choices={options.charge_type.choices}
        className="filter-field-small"
        label="Tipi di tariffe"
      />,
      <NullableBooleanInput {...initField("disabled", options)} />,

    ]
    : [];
  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      filterDefaultValues={{ tariff_type: "S", last_tariff: true }}
      {...getDefaultListProps(props)}
      bulkActionButtons={
        <>
          <TariffDuplicationBulkButton />
          <TariffExcelBulkButton />
          <BulkDeleteButton />
        </>
      }
    >
      <TariffDatagrid options={options} />
    </CustomList>
  ) : null;
};
