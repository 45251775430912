import { Card, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, makeStyles, Typography } from "@material-ui/core";
import { AppBar, AppBarProps, Button, FormWithRedirect, SaveButton, TextInput, useNotify, UserMenu } from "react-admin";
import { getUser } from "../authProvider";
import { ChangePassword } from "../Users";
import { ChangePasswordMenu } from "../Users/ChangePasswordMenu";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useToggler } from "../shared/hooks/useToggler";
import { useCallback, useEffect, useRef, useState } from "react";
import { client, refreshPresenceInfoView } from "../dataProvider";
import IconCancel from "@material-ui/icons/Cancel";
import { CircularProgress } from "@material-ui/core";
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import Webcam from "react-webcam";
import { QrReader } from "react-qr-reader";
import { set } from "lodash";




const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: "0.5em",
  },

  menuButton: { display: "none" },
});

const videoConstraints = {
  width: 800,
  height: 1200,
  facingMode: "environment",
};

interface LocationOptions {
  enableHighAccuracy?: boolean;
  timeout?: number;
  maximumAge?: number;
}

interface LocationState {
  coords: {
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  locatedAt: number | null;
  error: string | null;
}

const useLocation = (options: LocationOptions = {}) => {
  const [location, setLocation] = useState<LocationState>({
    coords: {
      latitude: null,
      longitude: null,
      accuracy: null,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      speed: null,
    },
    locatedAt: null,
    error: null,
  });

  useEffect(() => {
    // Ensuring this runs only in a client-side environment
    if (typeof window === "undefined" || !("geolocation" in navigator)) {
      setLocation((prevState) => ({
        ...prevState,
        error:
          "Geolocation is not supported by your browser or not available in the current environment",
      }));
      return;
    }

    const handleSuccess = (position: GeolocationPosition) => {
      setLocation({
        coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
          altitude: position.coords.altitude,
          altitudeAccuracy: position.coords.altitudeAccuracy,
          heading: position.coords.heading,
          speed: position.coords.speed,
        },
        locatedAt: position.timestamp,
        error: null,
      });
    };

    const handleError = (error: GeolocationPositionError) => {
      setLocation((prevState) => ({
        ...prevState,
        error: error.message,
      }));
    };

    const geoOptions = {
      enableHighAccuracy: options.enableHighAccuracy || false,
      timeout: options.timeout || Infinity,
      maximumAge: options.maximumAge || 0,
    };

    const watcher = navigator.geolocation.watchPosition(
      handleSuccess,
      handleError,
      geoOptions,
    );

    return () => navigator.geolocation.clearWatch(watcher);
  }, [options.enableHighAccuracy, options.timeout, options.maximumAge]);

  return location;
};


export const PresenceButton = (params: any) => {
  const { user, bigButton } = params;
  const { value, setTrue, setFalse } = useToggler();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [scanActive, setScanActive] = useState(true);
  const [startRoute, setStartRoute] = useState(false);
  const [notesIn, setNotesIn] = useState("");
  const [notesOut, setNotesOut] = useState("");

  const location = useLocation();

  const openPresence = (qr_code: any) => {
    if (location.coords.latitude && location.coords.longitude) {
      setLoading(true)
      client(`./presences/create_presence/?qr=${qr_code}&gps=${location.coords.latitude};${location.coords.longitude}&start_route=${startRoute}&notes_in=${notesIn}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.error) {
            notify(response.error, { type: "error" });
            return
          }
          else {
            setScanActive(false)
            setFalse()
            notify("Presenza registrata", { type: "info" });
            refreshPresenceInfoView()
          }

        })
        .catch((e: any) => {
          notify("Errore", { type: "error" });
        })
        .finally(() => {
          setLoading(false)
          setScanActive(false)
        });
    }
  };

  const closePresence = (qr_code: any) => {
    setLoading(true);

    client(`./presences/close_presence/?qr=${qr_code}&gps=${location.coords.latitude};${location.coords.longitude}&notes_out=${notesOut}`, {
      method: "GET",
    }).then((response) => {
      if (response.error) {
        notify(response.error, { type: "error" });
        return
      }
      else {
        setScanActive(false)
        setFalse()
        notify("Chiusura registrata", { type: "info" });
        refreshPresenceInfoView()
      }
    })
      .catch((error) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
        setScanActive(false)
      });
  };

  return (
    <div style={{
      cursor: 'pointer',
      backgroundColor: mouseIsOver ? "lightgray" : "white",
      borderRadius: "5px",
      marginLeft: "10px",
      marginRight: "10px",
    }}
      onMouseEnter={() => (
        setMouseIsOver(true)
      )}
      onMouseLeave={() => (
        setMouseIsOver(false)
      )}
    >
      {
        bigButton ? <Card onClick={(e) => {
          e.stopPropagation();
          setTrue();
          setScanActive(true);
          setMouseIsOver(false);
        }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <FiberManualRecordIcon
              style={{
                color: user.current_presence ? "green" : "red",
                height: "2em",
                width: "2em",
              }}
            />
            <Typography variant="h4" align="center">
              {user.current_presence ? "Registra uscita" : "Registra entrata"}
            </Typography>
          </Grid>
        </Card> : <FiberManualRecordIcon
          style={{
            color: user.current_presence ? "green" : "red",
            height: "1.3em",
            width: "1.3em",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setTrue();
            setScanActive(true);
            setMouseIsOver(false);
          }} />}

      {loading ? <CircularProgress size={18} thickness={2} /> : null}
      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="xs"
      >
        <FormWithRedirect
          resource="project-collaborators"
          save={() => { }}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>

                <Typography
                  variant="h3"
                  align="center">
                  ESEGUI {user.current_presence ? "USCITA" : "ENTRATA"}
                </Typography>
                <Typography
                  variant="h6"
                  align="center"
                  style={{ color: location.coords.latitude && location.coords.longitude ? "green" : "red" }} >
                  {location.coords.latitude && location.coords.longitude ? "GPS OK" : "ATTESA GPS"}
                  {location.coords.latitude && location.coords.longitude ? <a
                    href={`https://www.google.com/maps/search/?api=1&query=${location.coords.latitude},${location.coords.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" (Vedi posizione)"}
                  </a>
                    : null}
                </Typography>
                <QrReader
                  onResult={(result: any, error: any) => {
                    setScanActive(false)
                    if (scanActive === false)
                      return
                    if (!location.coords.latitude || !location.coords.longitude) {
                      notify("Attenzione attendere posizione GPS", { type: "error" });
                      return
                    }
                    if (result) {
                      if (user.current_presence) {
                        closePresence(result.text)
                      }
                      else {
                        openPresence(result.text)
                      }
                    }

                  }}
                  constraints={{ facingMode: 'environment' }}

                />
                {
                  user.current_presence ?
                    (<>
                      <TextInput style={{ width: "100%" }} source="notes_out" label="Note"
                        onChange={(e) => setNotesOut(e.target.value)}
                      />
                      <Button
                        onClick={() => {
                          closePresence("")
                        }}
                        style={{ width: "100%" }}
                      >
                        <Typography>FORZA USCITA</Typography>
                      </Button>
                    </>) : (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox checked={startRoute} onChange={(e) => setStartRoute(e.target.checked)} />
                          }
                          label="Avvia giro"
                        />
                        <TextInput style={{ width: "100%" }} source="notes_in" label="Note"
                          onChange={(e) => setNotesIn(e.target.value)}
                        />
                        <Button
                          onClick={() => {
                            openPresence("")
                          }}
                          style={{ width: "100%" }}
                        >
                          <Typography>FORZA ENTRATA</Typography>
                        </Button>
                      </>)
                }

              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                {/*
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
                */}
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </div>
  );
}

export const CustomAppBar = (props: AppBarProps) => {
  const [user, setUser] = useState(getUser());
  const classes = useStyles();
  useEffect(() => {
    setUser(getUser());
  }, [localStorage.getItem("user")]);
  return (

    <AppBar {...props}
      userMenu={
        user ?
          <>
            {
              user.is_internal_user && user.has_badge ?
                <PresenceButton user={user} />
                : null
            }
            <b>
              {user.username}
            </b>
            <UserMenu
              logout={props.logout}
              label={user.username}
            >
              <ChangePasswordMenu />
            </UserMenu>

          </> : false
      }
      position="sticky" classes={classes}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  );
};

