import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { getUser } from "../authProvider";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import PublishIcon from "@material-ui/icons/Publish";
import QueueIcon from "@material-ui/icons/Queue";
import MapIcon from "@material-ui/icons/Map";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import RoomIcon from "@material-ui/icons/Room";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ScannerIcon from '@material-ui/icons/Scanner';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { ChangePassword } from "../Users";
import { PresenceButton } from "../components/CustomAppBar";


interface FirstPageCardProps {
  hasPermission: boolean, title: string, url: string, icon: any
}

const FirstPageCard = (props: FirstPageCardProps) => {

  return props.hasPermission ? (
    <Grid item xs={12} md={4} lg={3} xl={2} >
      <a href={props.url}>
        <Card>
          <CardContent>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={3}>
                {props.icon}
              </Grid>
              <Grid item xs={9}>

                <Typography
                  variant="h5"
                  align="center">
                  {props.title}
                </Typography>

              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </a>
    </Grid >) : null;
};


export const FirstPage = (props: any) => {
  const user = getUser();

  const { permissions } = props;

  const iconSize = 60;

  const cards = [
    {
      hasPermission: permissions && permissions.includes('distribution.change_route') && permissions.includes('distribution.view_segment'),
      title: "TrackMe",
      url: "/trackme",
      icon: <RoomIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && (permissions.includes('distribution.view_note') || permissions.includes('base.view_carrierdelivery')),
      title: "DDT Scanner",
      url: "/ddt_scanner",
      icon: <ScannerIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && (permissions.includes('distribution.change_note') || permissions.includes('base.view_carrierdelivery')),
      title: "WebScanner",
      url: "/qr_scanner",
      icon: <PhotoCameraIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('registry.view_subject') && permissions.includes('registry.change_subject'),
      title: "Soggetti",
      url: "/subjects",
      icon: <PermContactCalendarIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('file_import.change_importedfile'),
      title: "Carica file",
      url: "/upload",
      icon: <PublishIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('distribution.view_delivery'),
      title: "Missioni",
      url: "/deliveries",
      icon: <QueueIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('distribution.change_route') && permissions.includes('distribution.change_delivery'),
      title: "Pianificatore",
      url: "/planner",
      icon: <MapIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('route_management.change_route') && permissions.includes('distribution.change_route') && permissions.includes('distribution.change_segment'),
      title: "Giri",
      url: "/routes",
      icon: <TimelineIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('route_management.view_route'),
      title: "Bacheca giri",
      url: "/board_routes",
      icon: <DeveloperBoardIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('route_management.view_route') && permissions.includes('distribution.change_route') && permissions.includes('distribution.view_segment'),
      title: "Carica giri",
      url: "/load-routes",
      icon: <PlaylistAddCheckIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('cash_on_delivery.display_cachet_to_cash_in'),
      title: "Da incassare",
      url: "/cachets",
      icon: <MoneyOffIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('cash_on_delivery.display_cachet_cashed'),
      title: "Incassi",
      url: "/cachet-payments",
      icon: <AttachMoneyIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('cash_on_delivery.view_remittance'),
      title: "Rimesse",
      url: "/remittances",
      icon: <TocIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('customers.view_customerorder'),
      title: "Ordini",
      url: "/customer_orders",
      icon: <QueueIcon style={{ fontSize: iconSize }} />
    },
    {
      hasPermission: permissions && permissions.includes('accounting.view_invoicepaymentstatus'),
      title: "Scadenzario",
      url: "/invoices-time-table",
      icon: <DateRangeIcon style={{ fontSize: iconSize }} />
    },
  ];

  return (

    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h3" align="center">Bentornato, {getUser().username}</Typography>
      </Grid>
      <Grid item xs={12}>
        {
          user.is_internal_user && user.has_badge ?
            <PresenceButton user={user} bigButton />
            : null
        }
      </Grid>


      <Grid item xs={12} >
        <Typography variant="h5" align="center">Sezioni</Typography>
      </Grid >
      {
        permissions ? (
          <>
            {

              cards.map((card) =>
                <FirstPageCard {...card} />
              )
            }
          </>
        )
          : null
      }

      <Grid item xs={12} >
        <Typography variant="h5" align="center">Funzioni utili</Typography>
      </Grid >

      <Grid item xs={12} >
        <ChangePassword />
      </Grid >

    </Grid>


  );
};
