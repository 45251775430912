import React from "react";
import { AutocompleteArrayInput, DateInput, DateTimeInput, ReferenceArrayInput, SimpleForm, TextInput } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";

export const PresenceQrForm = (props: FormProps) => {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
    >
      <TextInput
        {...initInput("code", props.fieldOptions, props.canEdit)}
      />
      <DateTimeInput
        {...initInput("valid_date", props.fieldOptions, props.canEdit)}
      />

    </SimpleForm>
  );
};
