import { AutocompleteInput, Datagrid, DateField, FunctionField, ListProps, NumberField, Record, ReferenceInput, SelectField, SelectInput, TextField, TextInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { DriverField } from "../../components/DriverField";

export const BadgeList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");


  const filters = options
    ? [
      /*

      <ReferenceInput
        {...initField("employee", options)}
        reference="autocomplete/employee"
        source="employee"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("user", options)}
        reference="autocomplete/user"
        source="user"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      */

    ]
    : [];

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <EmployeeField {...initField("employee_object", options)} label="Operatore" />
        <FunctionField
          label="Utente"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {

              return (
                <Typography>
                  {tableRecord["user_object"].id}: {tableRecord["user_object"].username}
                </Typography>
              );
            }
          }}
          sortable={false}
        />

        <NumberField {...initField("max_work_time", options)} label="Massime ore lavoro" />
      </Datagrid>
    </CustomList>
  ) : null;
};
