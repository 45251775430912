import { Datagrid, DateField, FunctionField, ListProps, SelectField, TextField, TextInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";

export const PresenceQrList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");


  const filters = options
    ? [

    ]
    : [];

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <TextField {...initField("code", options)} label="Qrcode" />
        <FunctionField
          label="Download Qr" render={
            (record: any) => {
              if (record && record.qr_code_base64_image) {
                return <>
                  <a href={"data:image/jpeg;base64," + record.qr_code_base64_image} download={record.code + ".png"}>Download</a>
                </>
              }
              return ''

            }} />

        <DateField {...initField("valid_date", options)} label="Valido fino al" showTime />
        <SelectField {...initField("type", options, "array")} choices={options.type.choices} />
      </Datagrid>
    </CustomList>
  ) : null;
};
