import { useClosedSidebar } from "../../shared/hooks/useClosedSdebar";
import { PresenceQrGenerator } from "../../components/PresenceQrGenerator";
import { getUser } from "../../authProvider";


export const PresenceQrGeneratorPage = (props: any) => {

  useClosedSidebar();

  return (
    <>
      <div>
        <PresenceQrGenerator {...{ compact: false, show_info: true }} />
      </div>
    </>
  );
};