import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  makeStyles,
  List,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StepConnector,
} from "@material-ui/core";
import {
  Identifier,
  useQueryWithStore,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { Log } from "../shared/types";
import { HistoryOutlined, PlusOneOutlined } from "@material-ui/icons";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useState } from "react";

const useAsideStyles = makeStyles((theme) => ({
  root: {
    //width: 700,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
const useEventStyles = makeStyles({
  stepper: {
    background: "none",
    border: "none",
    padding: "8px"
  },
});
const EventList = ({ id }: { id: Identifier }) => {
  const resource = useResourceContext();

  const { data } = useQueryWithStore({
    type: "getHistory",
    resource: resource,
    payload: { id },
  });
  const classes = useEventStyles();
  return (
    <Stepper orientation="vertical" classes={{ root: classes.stepper }}
      connector={<StepConnector style={{ minHeight: "8px" }}></StepConnector>}>
      {data &&
        data.map((event: Log, index: number) => (
          <Step key={index} expanded active completed >
            <StepLabel
              StepIconComponent={() => {
                const Component =
                  event.history_type === "Creato"
                    ? PlusOneOutlined
                    : HistoryOutlined;
                return (
                  <Component
                    fontSize="small"
                    color="disabled"
                    style={{ paddingLeft: 3 }}
                  />
                );
              }}
            >
              <b>{event.history_user}</b> {new Date(event.history_date).toLocaleString()}
            </StepLabel>
            <StepContent style={{
              marginTop: "0px"
            }}>
              <List style={{
                padding: "0px"
              }}>
                {event.changed_fields.map((item: string, index: number) => (
                  <ListItem key={index} style={{
                    textTransform: "capitalize",
                    padding: "0px 0px 0px 16px"
                  }}>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: item }}
                      variant="body2"
                      color="textSecondary"
                    ></Typography>
                  </ListItem>
                ))}
              </List>
            </StepContent>
          </Step>
        ))}
      <br />
      <div>
        --------------------------------------------
      </div>
      <Typography variant="h5" color="error">Legenda </Typography>
      <span>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAagAAAGoB3Bi5tQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAD2SURBVDiN7ZM9akJBFIW/I0MqCcQyTYJYpcoC3iLUgBuwscwmFEvr7EFJdpDCKhDIErTQ8gWCVYoci4xheD/kLcADF+aee+beM8yMbFOEpAVwX6A/bD8WtSHZ1AIugesY/YL2SdIdsAe+bP8AyDaSusAS2JbsVOMGGNjeEI8wAWa2aRLADBjb/nPQiQ46wOc/06+AHHiwnQcA27mkF2Bj+1lS2/ZB0kWsfydcH7i1nQO0aqbMJfWAETCK63mVMFSRkQ9JPdRp6xw0xrlB/S2sgB3wFvNd5MpInmcGTBs84ymQnfKQNFpLGkp6Bcp//BcC3m2vT8QRxYWXXI9WI/YAAAAASUVORK5CYII="
        />: Elemento eliminato
      </span>
      <span>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAdgAAAHYBTnsmCAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADwSURBVDiNndO9LoRREMbxX4LrUAhiL8RXJ3sDq6ATu3EJKhKv2EQnKpdApZFYVEIIF6FYFEJkbRTzbnKyPpLzTjmZ/8yc5znDz6ihjUd84A132MFEUtfAdQqOocAXOmhhEfPYwCUOy9pl9LCSwid4Qf2XrdIYwKtpssAzpqvAtTKZO/lAqUkb5xUmd7BJqN3MXVsIe0tYNZcJEw49wfsfDf6DYQGv8ID1TJh49hXs4SwTJkTcJrw/yoSX8Inx3LVhEl1sVYHr4sceY2SQbAzB+zjFGmaF2k1coF9OHk273kiuCjPYxb2wqSvOtsDU8ErfWIFJ/eAVXRwAAAAASUVORK5CYII="
        /> :
        Vuoto
      </span>
    </Stepper>
  );
};
export const AsideLog = () => {
  const record = useRecordContext();

  const [accWidth, setAccWidth] = useState(false);

  return (
    <Accordion style={{
      zIndex: 10000, position: "fixed", right: "10px",
      width: accWidth ? "50%" : "150px",
    }}>
      <AccordionSummary
        expandIcon={<ChevronLeftIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          height: accWidth ? "40px" : "25px",
          minHeight: accWidth ? "40px" : "25px",
          backgroundColor: accWidth ? "white" : "lightgrey"
        }}
        onClick={() => {
          setAccWidth(!accWidth)
        }}
      >
        <Typography variant="h6" gutterBottom>
          <HistoryOutlined style={{ verticalAlign: "middle" }} />  Logs
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{
        overflow: "auto", maxHeight: `${window.screen.height - 450}px`,
        padding: "8px"
      }}>
        {record && accWidth && <EventList id={record.id} />}
      </AccordionDetails>
    </Accordion >

    /*
    <Card
      //className={classes.root}
      style={{ position: "absolute", height: "90%" }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Logs
        </Typography>
        {record && <EventList id={record.id} />}
      </CardContent>
    </Card>
    */
  );
};
