import { ListProps, required, Record } from "react-admin";
import { CustomPagination } from "../components/CustomPagination";

export function initField(source: string, options: any, type?: "array") {
  return {
    source,
    label: options[source]?.label,
    ...(type && type === "array"
      ? {
        optionText: "display_name",
        optionValue: "value",
      }
      : {}),
  };
}

export function getDefaultListProps(props: ListProps) {
  return { ...props, perPage: 50, pagination: <CustomPagination /> };
}

export function initInput(
  source: string,
  options: any,
  canEdit?: any,
  type?: "array"
) {
  return {
    source,
    required: options[source]?.required,
    validate: options[source]?.required ? [required()] : [],
    label: options[source]?.label,
    disabled:
      canEdit === false
        ? true
        : !!options[source]?.read_only
          ? options[source].read_only
          : false,
    fullWidth: true,
    variant: "outlined" as "outlined",
    ...(type && type === "array"
      ? {
        optionText: "display_name",
        optionValue: "value",
      }
      : {}),
  };
}

export const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export function getFileNameFromContentDisposition(contentDisposition: any) {
  if (!contentDisposition) return null;

  const match = contentDisposition.match(/filename="?([^"]+)"?/);
  return match ? match[1] : null;
}
export function subtractMonth(date: Date, months: number) {
  const copy = new Date(date);
  let d = copy.getDate();

  copy.setMonth(copy.getMonth() - months);
  if (copy.getDate() !== d) {
    copy.setDate(0);
  }
  return copy;
}

export const dateFormatter = (v: Date) => {
  // v is a `Date` object
  if (!(v instanceof Date) || Number.isNaN(v)) return;
  const pad = "00";
  const yy = v.getFullYear().toString();
  const mm = (v.getMonth() + 1).toString();
  const dd = v.getDate().toString();
  return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateTimeFormatter = (v: Date) => {
  // v is a `Date` object
  if (!(v instanceof Date) || Number.isNaN(v)) return;
  const pad = "00";
  const yy = v.getFullYear().toString();
  const mm = (v.getMonth() + 1).toString();
  const dd = v.getDate().toString();
  const hh = v.getHours().toString();
  const min = v.getMinutes().toString();
  return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}T${(pad + hh).slice(-2)}:${(pad + min).slice(-2)}`;
};

export const getResidue = (record: Record) => {
  return (
    Math.round(
      (record.total -
        (record.cash + record.check + record.credit_card) - record.rebate) *
      100)
    / 100
  );
};

export const generateError = (error: any, notify: any) => {

  if (error.response.data) {
    if ("non_field_errors" in error.response.data) {
      notify(error.response.data.non_field_errors.join("/n"), "error");
      return;
    }
    try {
      if ("detail" in error.response.data) {
        notify(error.response.data.detail.toString(), "error");
        return;
      }
      else if ("parent_delivery" in error.response.data) {
        notify(error.response.data.parent_delivery.toString(), "error");
        return;
      }
      else if ("delivery" in error.response.data) {
        notify(error.response.data.delivery.toString(), "error");
        return;
      }
      else if ("sender" in error.response.data || "new_sender" in error.response.data) {
        notify("Mittente: " + error.response.data.sender.toString(), "error");
        return;
      }

    } catch (error) {
      notify("Error", "error");
      return;
    }

    let errorDict: any = {};
    Object.keys(error.response.data).forEach((key) => {
      errorDict[key] = error.response.data[key].join(". ");
    });
    return errorDict;
  }
};


export const formatThousandsNumber = (number: number) => {
  const value = number + '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `.${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `,${list[1]}` : ''}`;
};
