import { Box } from "@material-ui/core";
import { rest } from "lodash";
import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  DateInput,
  ListProps,
  NumberField,
  ReferenceArrayInput,
  SelectField,
  SelectInput,
} from "ra-ui-materialui";
import {
  BooleanInput,
  FunctionField,
  Link,
  linkToRecord,
  NullableBooleanInput,
  TextField,
} from "react-admin";
import { CustomList } from "../components/CustomList";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../shared/hooks/useOptions";
import { getDefaultListProps, initField } from "../shared/utils";
import { ShowPaymentStatusHistoryButton } from "./components/ShowPaymentStatusHistoryButton";
import { NumberInput } from "../components/NumberInput";
import { TariffType } from "../shared/types";
import { useEffect, useState } from "react";
import { AddPurcasePaymentStatusButton } from "./components/AddPurcasePaymentStatusButton";
import { ReferenceInputAccountCompany } from "../components/reference-inputs/ReferenceInputAccountCompany";
import { InvoiceTimeTableDatagrid } from "./InvoiceTimeTableList";
import { InvoiceTimeTableListHeader } from "./InvoiceTimeTableListHeader";
export const PurchaseInvoiceTimeTableList = (props: ListProps) => {
  const { data: options } = useOptions("invoices", "POST");
  const { data: optionsTerms } = useOptions("payment-terms", "GET");
  const { data: optionsStatus } = useOptions("invoice-payment-status", "POST");

  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    if (optionsStatus) {
      let statusListCorrect = optionsStatus.status.choices;
      for (let s of statusListCorrect) {
        switch (s.value) {
          case "I":
            s.display_name = "Fattura pagata";
            break;
          case "S":
            s.display_name = "Sollecito ricevuto";
            break;
          default:
            break;
        }
      }
      setStatusList(statusListCorrect);
    }
  },
    [optionsStatus]
  )
  const filters =
    options && optionsStatus && statusList && optionsTerms
      ? [
        <ReferenceInputAccountCompany
          {...initField("company", options)}
          alwaysOn
        />,
        <NullableBooleanInput label="Pagata" source={"not_cashed"} />,
        <NullableBooleanInput {...initField("chart_account_handle", options)} alwaysOn label="Piano dei conti assegnato" />,
        <ReferenceArrayInput
          reference="autocomplete/chartaccounts"
          source={"chartaccounts"}
          label="Piano dei conti"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
        <DateInput source="invoice_date_after" label="Dal" />,
        <DateInput source="invoice_date_before" label="Al" />,
        <NumberInput
          {...initField("sequence_number", options)}
          label="N° sequenza"
        />,
        <NumberInput {...initField("id", options)} />,

        <ReferenceInputSubject
          filter={{ type: "supplier" }}
          {...initField("supplier", options)}
        />,
        <SelectInput
          choices={statusList}
          {...initField("last_status", optionsStatus, "array")}
          label="Ultimo stato"
        />,
        <DateInput source="last_status__date_after" label="Data ultimo stato dal" />,
        <DateInput source="last_status__date_before" label="Data ultimo stato al" />,
        <SelectInput
          choices={optionsTerms.method.choices}
          {...initField("payment_method", optionsTerms, "array")}
          label="Metodo di pagamento"
        />,
        <DateInput
          {...initField("expiration_date_after", options)}
          label="Data scadenza dal"
        />,
        <DateInput
          {...initField("expiration_date_before", options)}
          label="Data scadenza fino al"
        />,
      ]
      : [];

  return options && optionsStatus && statusList ? (
    <CustomList
      sort={{ field: "expiration_date", order: "DESC" }}
      filters={filters}
      filter={{ invoice_type: TariffType.PURCHASE }}
      {...getDefaultListProps(props)}
      bulkActionButtons={<></>}
      filterDefaultValues={{ not_cashed: false, company: 1 }}
      topElement={<InvoiceTimeTableListHeader {...{ passive: true }} />}
    >
      <InvoiceTimeTableDatagrid {...{ passive: true }} />
    </CustomList>
  ) : null;
};
