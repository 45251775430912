import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  BooleanInput,
  Button,
  DateInput,
  FormDataConsumer,
  FormWithRedirect,
  required,
  SelectInput,
  TextInput,
  useCreate,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, generateError, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { NumberInput } from "../../components/NumberInput";
import { useCallback } from "react";

export const AddPurcasePaymentStatusButton = () => {
  const record = useRecordContext();
  const { data: termsPayment } = useGetOne(
    "payment-terms",
    record.terms_payment_id
  );
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    create("invoice-payment-status", values, {
      onSuccess: (data) => {
        setFalse();
        refresh();
      },
      onFailure: (error) => {
        generateError(error, notify);
      },
    });
  };
  const paymentStatusChoices = useCallback(() => {
    if (termsPayment && termsPayment.method_code === "MP12")
      return [
        { value: "NE", display_name: "Non emessa" },
        { value: "E", display_name: "Emessa" },
        { value: "IN", display_name: "Insoluta" },
      ];
    return [
      { value: "I", display_name: "Fattura pagata" },
      { value: "A", display_name: "Acconto" },
      { value: "S", display_name: "Sollecito ricevuto" },
    ];
  }, [termsPayment]);
  const { data: options } = useOptions("invoice-payment-status", "POST");
  return options ? (
    <>
      <Button
        variant="outlined"
        label="Aggiorna stato"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="invoice-payment-status"
          save={handleSubmit}
          initialValues={{ invoice: record.id }}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <DateInput
                  initialValue={dateFormatter(new Date())}
                  {...initInput("date", options, true)}
                />
                <SelectInput
                  {...initInput("status", options, true, "array")}
                  choices={paymentStatusChoices()}
                />
                <FormDataConsumer>
                  {({ formData }) => (
                    <>
                      {formData.status === "A" && (
                        <NumberInput
                          {...initInput("amount", options, true)}
                          validate={[required()]}
                        />
                      )}
                      {["A", "I"].includes(formData.status) && (
                        <SelectInput
                          {...initInput(
                            "collection_type",
                            options,
                            true,
                            "array"
                          )}
                          choices={options.collection_type.choices}
                          validate={[required()]}
                        />
                      )}
                    </>
                  )}
                </FormDataConsumer>
                <TextInput {...initInput("notes", options, true)} />

              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
