import React, { useEffect } from "react";
import {
  ReferenceInput,
  Datagrid,
  TextField,
  SelectField,
  DateField,
  NumberField,
  FunctionField,
  ArrayField,
} from "react-admin";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initInput, initField } from "../../shared/utils";
import { Record } from "react-admin";
import { useFormState } from "react-final-form";
import { TariffClientDatagrid } from "../../components/TariffClientDatagrid";
interface DeliveryAddTariffFieldProps {
  options: any;
}
export const DeliveryAddTariffField = (props: DeliveryAddTariffFieldProps) => {
  const { options } = props;
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { values } = useFormState();
  const disabled = values.client && values.receiver && values.starting_point_on_delivery ? false : true;

  useEffect(() => {
    values.new_service = null
  },
    [values.client, values.receiver, values.starting_point_on_delivery, values.delivery_type])

  return optionsTariffs ? (
    <ReferenceInput
      {...initInput("new_service", options, !disabled)}
      reference="tariffs-client"
      filter={{
        client: values.client,
        document_date: values.document_date,
        delivery_type: values.delivery_type,
        receiver: values.receiver,
        starting_point_on_delivery: values.starting_point_on_delivery,
      }}
      helperText={false}
      sort={{ field: 'priority', order: 'DESC' }}
    >
      <DialogSelectInput helperText={false} optionText="label"
        styleMapFunction={(choices: any, choice: any) => {
          let rest_style = {};
          for (let c of choices) {
            if (c.id === choice) {
              let color = "#ffffff"
              if (c.tariff.priority === 0) {
                color = "#ffffff"
              }
              else if (c.tariff.priority === 1) {
                color = "#ffb3b3"
              }
              else {
                color = "#ff3333"
              }
              rest_style = { backgroundColor: color }
            }

          }
          return rest_style
        }}>
        <TariffClientDatagrid options={optionsTariffs} />
      </DialogSelectInput>
    </ReferenceInput>
  ) : null;
};
