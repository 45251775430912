import { Box, CircularProgress, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { rest } from "lodash";
import {
  Datagrid,
  DateField,
  DateInput,
  ListProps,
  NumberField,
  SelectField,
  SelectInput,
} from "ra-ui-materialui";
import {
  BooleanInput,
  BulkActionProps,
  Button,
  DatagridProps,
  DateTimeInput,
  FormWithRedirect,
  FunctionField,
  Identifier,
  Link,
  linkToRecord,
  NullableBooleanInput,
  SaveButton,
  TextField,
  TextInput,
  useListContext,
  useListController,
  useNotify,
  useRefresh,
} from "react-admin";
import { CustomList } from "../components/CustomList";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../shared/hooks/useOptions";
import { dateFormatter, getDefaultListProps, getFileNameFromContentDisposition, initField, initInput } from "../shared/utils";
import { AddInvoicePaymentStatusButton } from "./components/AddPaymentStatusButton";
import { ShowPaymentStatusHistoryButton } from "./components/ShowPaymentStatusHistoryButton";
import { NumberInput } from "../components/NumberInput";
import { TariffType } from "../shared/types";
import { InvoiceTimeTableListHeader } from "./InvoiceTimeTableListHeader";
import { ReferenceInputAccountCompany } from "../components/reference-inputs/ReferenceInputAccountCompany";
import { DownloadInvoicePdfButton } from "./components/DownloadInvoicePdfButton";
import { useToggler } from "../shared/hooks/useToggler";
import { useEffect, useMemo, useState } from "react";
import { clientNoJson } from "../dataProvider";
import SendIcon from '@material-ui/icons/Send';
import { FormSaveButton } from "../components/FormSaveButton";
import IconCancel from "@material-ui/icons/Cancel";
import PublishIcon from "@material-ui/icons/Print";
import { AddPaymentStatusPayedBulkButton } from "./components/AddPaymentStatusPayedBulkButton";
import { AddPurcasePaymentStatusButton } from "./components/AddPurcasePaymentStatusButton";


const PrintInvoiceTimeTableButtonSingleClient = ({ ids }: { ids?: Identifier[] }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;
    try {
      res = await clientNoJson(`/api/invoices-time-table/print_pdf_single_client/`, {
        method: "POST",
        data: {
          ids: ids,
        },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);


  };

  return (
    <Button
      disabled={loading}
      label="Stampa scadenzario (singolo cliente)"
      title="Stampa estratto conto con attività"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <PublishIcon />}
    </Button>
  );
};

const PrintInvoiceTimeTableButton = ({ ids }: { ids?: Identifier[] }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;
    try {
      res = await clientNoJson(`/api/invoices-time-table/print_pdf/`, {
        method: "POST",
        data: {
          ids: ids,
        },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);


  };

  return (
    <Button
      disabled={loading}
      label="Stampa scadenzario"
      title="Stampa estratto conto con attività"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <PublishIcon />}
    </Button>
  );
};


const SendInvoiceButton = ({ ids }: { ids?: Identifier[] }) => {
  const { value: dialogOpen, setFalse: closeDialog, setTrue: openDialog } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);


  const handleSubmit = async (values: any) => {

    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`api/invoice-payment-status/reminder_emails/`, {
        method: "POST",
        data: {
          ids: ids,
          ...values
        },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    notify("Solleciti generati", "success");
    try {
      refresh();
      closeDialog();
    }
    catch (e) {

    }

  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Genera sollecito ed invia email"
        onClick={() => openDialog()}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <><SendIcon /></>}
      </Button>
      <Dialog
        onClick={(e) => e.stopPropagation()}
        open={dialogOpen} maxWidth="sm" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <DateInput
                  initialValue={dateFormatter(new Date())}
                  source={"date"}
                  label={"Data sollecito"}
                  canEdit={true}
                  fullWidth
                />
                <TextInput
                  source={"email_text"}
                  label={"Data sollecito"}
                  canEdit={true}
                  multiline
                  defaultValue={
                    "Gentile cliente,\nriportiamo in allegato il Suo estratto conto relativo al ns avere alla data odierna.\nLa preghiamo pertanto di regolarizzare al più presto la sua posizione contabile, ricordando che il pagamento può essere effettuato a mezzo bonifico bancario, utilizzando i riferimenti presenti nella fattura elettronica.\n\nNel caso in cui il bonifico sia stato già disposto, potrà ritenere nulla la presente richiesta.\nIn attesa di suo gradito riscontro e restando a disposizione per eventuali delucidazioni,\nci è gradita l’occasione per porgere distinti saluti."
                  }
                  rows={10}
                  fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={closeDialog}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const SendInvoiceSumButton = ({ ids }: { ids?: Identifier[] }) => {
  const { value: dialogOpen, setFalse: closeDialog, setTrue: openDialog } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);


  const handleSubmit = async (values: any) => {

    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`api/invoice-payment-status/reminder_sum_emails/`, {
        method: "POST",
        data: {
          ids: ids,
          ...values
        },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    notify("Solleciti generati", "success");
    try {
      refresh();
      closeDialog();
    }
    catch (e) {

    }

  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Genera sollecito COMPATTO ed invia email"
        onClick={() => openDialog()}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <><SendIcon /></>}
      </Button>
      <Dialog
        onClick={(e) => e.stopPropagation()}
        open={dialogOpen} maxWidth="sm" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <DateInput
                  initialValue={dateFormatter(new Date())}
                  source={"date"}
                  label={"Data sollecito"}
                  canEdit={true}
                  fullWidth
                />
                <TextInput
                  source={"email_text"}
                  label={"Data sollecito"}
                  canEdit={true}
                  multiline
                  defaultValue={
                    "Gentile cliente,\nriportiamo in allegato il Suo estratto conto relativo al ns avere alla data odierna.\nLa preghiamo pertanto di regolarizzare al più presto la sua posizione contabile, ricordando che il pagamento può essere effettuato a mezzo bonifico bancario, utilizzando i riferimenti presenti nella fattura elettronica.\n\nNel caso in cui il bonifico sia stato già disposto, potrà ritenere nulla la presente richiesta.\nIn attesa di suo gradito riscontro e restando a disposizione per eventuali delucidazioni,\nci è gradita l’occasione per porgere distinti saluti."
                  }
                  rows={10}
                  fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={closeDialog}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};




export const BulkActions = (props: BulkActionProps) => {
  const { selectedIds } = props;

  return (
    <>
      <PrintInvoiceTimeTableButtonSingleClient ids={selectedIds} />
      <PrintInvoiceTimeTableButton ids={selectedIds} />
      <SendInvoiceSumButton ids={selectedIds} />
      <SendInvoiceButton ids={selectedIds} />
      <AddPaymentStatusPayedBulkButton ids={selectedIds} {...props} />
    </>
  );
};

export type InvoiceTimeTableDatagridProps = DatagridProps & {

};

export const InvoiceTimeTableDatagrid = (props: InvoiceTimeTableDatagridProps & { passive?: boolean }) => {
  const { ...rest } = props;
  const passive = props.passive || false;
  const { data: options } = useOptions("invoices", "POST");
  const { data: optionsStatus } = useOptions("invoice-payment-status", "POST");


  return (
    <Datagrid
      {...rest}
      rowStyle={(record: any) => {
        return {
          backgroundColor:
            record.type === "NC" ? "#ffff0050" : "white",
        };
      }}
    >
      <FunctionField
        label="ID"
        source="id"
        render={(record: any) => {
          if (record) {
            return (
              <Link to={linkToRecord("/invoices", record.id, "show")}>
                {
                  <TextField
                    record={record}
                    source={"id"}
                    variant="inherit"
                  />
                }
              </Link>
            );
          }
          return null;
        }}
      />

      <FunctionField
        label="Fattura"
        source="id"
        render={(record: any) => {
          if (record) {
            return (
              <Link to={linkToRecord("/invoices", record.id, "show")}>
                {
                  record.sequence_number ?
                    <TextField
                      record={record}
                      source={"sequence_number"}
                      variant="inherit"
                    />
                    :
                    <TextField
                      record={record}
                      source={"invoice_number"}
                      variant="inherit"
                    />
                }
              </Link>
            );
          }
          return null;
        }}
        sortBy="sequence_number"
      />

      <TextField
        {...initField("type", options)}
        label="Tipo F"
      />

      <FunctionField
        label="Azienda"
        source="company_object"
        render={(record: any | undefined) => {
          if (record && record.company_object) {
            return (
              <Link to={linkToRecord("/subjects", record.company_object.company.id, "edit")}>
                <TextField
                  record={record.company_object}
                  source="name"
                  variant="inherit"

                />
              </Link>
            );
          }
          return null;
        }}
        sortBy="company__company__business_name"
      />
      <DateField {...initField("invoice_date", options)} />


      <FunctionField
        label="Cliente"
        source="client_id"
        render={(record: any) => {
          if (record) {
            return (
              <Link to={linkToRecord("/subjects", record.client.id, "edit")}>
                <TextField
                  record={record.client}
                  source="business_name"
                  variant="inherit"
                />
              </Link>
            );
          }
          return null;
        }}
        sortBy="client__business_name"
      />
      <NumberField
        {...initField("amount", options)}
        label="Importo"
        options={{
          style: "currency",
          currency: "EUR",
        }}
      />
      <NumberField
        {...initField("residue", options)}
        label="A saldo"
        options={{
          style: "currency",
          currency: "EUR",
        }}
      />
      {/* a saldo */}
      <FunctionField
        {...initField("expiration_date", options)}
        label="Data scadenza"
        render={(record: any) => {
          const color =
            new Date(record.expiration_date) > new Date() ? "green" : "red";
          return (
            <Box display={"flex"} alignItems={"center"}>
              {((record.last_status && record.last_status.status !== "I") ||
                !record.last_status) && (
                  <div
                    style={{
                      backgroundColor: color,
                      width: "30px",
                      height: "30px",
                      borderRadius: "100%",
                      marginRight: "10px",
                    }}
                  />
                )}
              <DateField {...initField("expiration_date", options)} />
            </Box>
          );
        }}
      />
      <TextField {...initField("terms_payment", options)} />

      <DownloadInvoicePdfButton label="PDF fattura" />

      <FunctionField
        {...initField("last_status", options)}
        label={"Stato pagamento"}
        render={(record: any) => (
          <SelectField
            record={record.last_status}
            {...initField("status", optionsStatus, "array")}
            choices={optionsStatus.status.choices}
          />
        )}
        sortBy={"last_status__status"}
      />
      <DateField
        {...initField("last_status__date", options,)}
        label={"Data ultimo stato"}
      />
      <FunctionField
        label={"Note"}
        render={(record: any) => (
          <TextField
            record={record.last_status}
            {...initField("notes", optionsStatus, "array")}
          />
        )}
      />
      <FunctionField
        render={(record: any) =>
          record?.last_status?.status !== "I" ? (
            passive === true ?
              <AddPurcasePaymentStatusButton /> :
              <AddInvoicePaymentStatusButton />
          ) : null
        }
      />
      <ShowPaymentStatusHistoryButton />
    </Datagrid>
  )
};





export const InvoiceTimeTableList = (props: ListProps) => {
  const { data: options } = useOptions("invoices", "POST");
  const { data: optionsTerms } = useOptions("payment-terms", "GET");
  const { data: optionsStatus } = useOptions("invoice-payment-status", "POST");

  const filters =
    options && optionsStatus && optionsTerms
      ? [
        <ReferenceInputAccountCompany
          {...initField("company", options)}
          alwaysOn
        />,
        <NullableBooleanInput label="Incassata" source={"not_cashed"} />,
        <DateInput source="invoice_date_after" label="Dal" />,
        <DateInput source="invoice_date_before" label="Al" />,
        <NumberInput
          {...initField("sequence_number", options)}
          label="N° fattura"
        />,
        <NumberInput {...initField("id", options)} />,

        <ReferenceInputSubject
          filter={{ type: "client" }}
          {...initField("client", options)}
          alwaysOn
        />,
        <SelectInput
          choices={optionsStatus.status.choices}
          {...initField("last_status", optionsStatus, "array")}
          label="Ultimo stato"
        />,
        <DateInput source="last_status__date_after" label="Data ultimo stato dal" />,
        <DateInput source="last_status__date_before" label="Data ultimo stato al" />,
        <SelectInput
          choices={optionsTerms.method.choices}
          {...initField("payment_method", optionsTerms, "array")}
          label="Metodo di pagamento"
        />,
        <DateInput
          {...initField("expiration_date_after", options)}
          label="Data scadenza dal"
        />,
        <DateInput
          {...initField("expiration_date_before", options)}
          label="Data scadenza fino al"
        />,
      ]
      : [];

  return options && optionsStatus ? (
    <CustomList
      sort={{ field: "expiration_date", order: "DESC" }}
      filters={filters}
      filter={{ invoice_type: TariffType.SALE }}
      {...getDefaultListProps(props)}
      //bulkActionButtons={false}
      bulkActionButtons={<BulkActions />}
      filterDefaultValues={{ not_cashed: false, company: 1 }}
      topElement={<InvoiceTimeTableListHeader />}
    >
      <InvoiceTimeTableDatagrid
      />
    </CustomList>
  ) : null;
};
