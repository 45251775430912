import React from "react";
import {
  ArrayField,
  Datagrid,
  DatagridProps,
  DateField,
  FunctionField,
  NumberField,
  SelectField,
  TextField,
} from "react-admin";
import { InvoiceField } from "../../components/InvoiceField";
import { SubjectField } from "../../components/SubjectField";

export const InvoiceChartAccountsGroupedDatagrid = (props: DatagridProps) => {
  return (
    <Datagrid {...props}>
      <NumberField source="id" label="ID" />
      <TextField source="chart_accounts_objects.code" label="Cod" />
      <TextField source="chart_accounts_objects.name" label="P.d.C." />
      <NumberField source="amount" label={"Importo"} />
      <InvoiceField label="Fattura" source="invoice_info" />
      <DateField label="Data fattura" source="invoice_info.invoice_date" />
    </Datagrid>
  );
};
