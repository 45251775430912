import { Card } from "@material-ui/core";
import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { PresenceQrForm } from "./PresenceQrForm";

export const PresenceQrCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component={Card}>
      <PresenceQrForm />
    </CustomCreate>
  );
};
