import { get } from "lodash";
import React from "react";
import { FieldProps, useRecordContext } from "react-admin";

export const EmployeeField = (props: FieldProps & { source: string }) => {
  const record = useRecordContext(props);
  const value = get(record, props.source);
  return (
    <>
      {value && value
        ? `${value.id}: ${value.surname} ${value.name}`
        : null}
    </>
  );
};
