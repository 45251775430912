import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Loading, Record, Tab, useCreate, useListContext } from "react-admin";
import { Subject } from "../shared/types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useDialoglUpdate } from "../shared/hooks/useDialogUpdate";
import { SubjectFormContent } from "../Subjects/SubjectFormContent";
import { FormSaveButton } from "../components/FormSaveButton";
import { CancelButton } from "../components/CancelButton";
import { CustomForm } from "../components/CustomForm";
import { SimilSubjectsTable } from "./SimilSubjectsTable";
import { useAssignMissingSubject } from "./useAssignMissingSubject";
import { useCallback, useMemo, useState } from "react";
import { useCopyData } from "../shared/hooks/useCopyData";
import { useSaveWithErrors } from "../shared/hooks/useSaveWithErrors";
import { useOptions } from "../shared/hooks/useOptions";

export interface SubjectNotFound {
  not_found_subject: {
    id: number | string;
    business_name: string;
    address: string;
    locality: string;
    province: string;
    postal_code: string;
  };
  results: Partial<Subject>[];
}

export const SubjectSolverGrid = () => {
  const { data, ids, loading } = useListContext();
  const records = useMemo(() => {
    return ids.map((id) => data[id]);
  }, [data, ids]);
  const [actualData, setActualData] = useCopyData<Record[]>([], records);
  const { data: options } = useOptions("subjects", "GET");
  const {
    isOpenUpdateModal,
    activeItem: activeMissingSubject,
    setActiveItem: setActiveMissingSubject,
    toggleUpdateModal,
  } = useDialoglUpdate();
  const [create] = useCreate("subjects");
  const save = useSaveWithErrors(create, "created");
  const { mutateAsync: assignSubject } = useAssignMissingSubject();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const totalPages = useMemo(() => {
    return Math.ceil(ids.length / perPage) || 1;
  }, [perPage, ids.length]);

  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || page > totalPages - 1) {
        throw new Error();
      }
      setPage(page + 1);
    },
    [totalPages, setPage]
  );

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage]
  );

  const saveSubject = (data: any) => {
    return save(data, {
      onSuccess: ({ data }) => {
        if (activeMissingSubject) {
          assignSubject({
            not_found_subject: activeMissingSubject.id,
            subject_id: data!.id!,
          }).then(() => {
            setActualData((oldData) => {
              return oldData.filter((notFoundObject) => {
                return (
                  `${notFoundObject.not_found_subject.business_name},
              ${notFoundObject.not_found_subject.address} 
              ${notFoundObject.not_found_subject.locality}` !==
                  `${activeMissingSubject.business_name},
              ${activeMissingSubject.address} 
              ${activeMissingSubject.locality}`
                );
              });
            });
          });
          setActiveMissingSubject(undefined);
        }
      },
    });
  };
  if (loading || !options) return <Loading />;
  return (
    <>
      {actualData
        .slice((page - 1) * perPage, page * perPage)
        .map((notFoundObject, index) => {
          return (
            <Box key={index} marginBottom={2}>
              <Paper>
                <Box padding={1}>
                  <Box padding={1}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={11}>
                        <Table >
                          <TableHead>
                            <TableRow >
                              <TableCell style={{ color: "red", padding: "0px" }} >
                                Soggetto non trovato nel sistema:
                              </TableCell>
                              <TableCell style={{ color: "red", padding: "0px" }}>
                                Tipo
                              </TableCell>
                              {notFoundObject.not_found_subject.info.client_id ?
                                <TableCell style={{ color: "red", padding: "0px" }}>
                                  Cliente
                                </TableCell> : null}

                              {notFoundObject.not_found_subject.info.customer_subject_id ?
                                <TableCell style={{ color: "red", padding: "0px" }}>
                                  Soggetto ordine
                                </TableCell> : null}

                              {notFoundObject.not_found_subject.info.delivery_id ?
                                <TableCell style={{ color: "red", padding: "0px" }}>
                                  Delivery
                                </TableCell> : null}

                              {notFoundObject.not_found_subject.info.import_file_id ?
                                <TableCell style={{ color: "red", padding: "0px" }}>
                                  File
                                </TableCell> : null}

                              {notFoundObject.not_found_subject.info.user ?
                                <TableCell style={{ color: "red", padding: "0px" }}>
                                  Utente
                                </TableCell> : null}

                            </TableRow>
                          </TableHead>
                          <TableRow>
                            <TableCell style={{ padding: "0px" }}>
                              {`${notFoundObject.not_found_subject.business_name},
                              ${notFoundObject.not_found_subject.address} 
                              ${notFoundObject.not_found_subject.locality}`}
                            </TableCell>

                            <TableCell style={{ padding: "0px" }}>
                              {notFoundObject.not_found_subject.info.type}
                            </TableCell>

                            {notFoundObject.not_found_subject.info.client_id ?
                              <TableCell style={{ padding: "0px" }}>
                                {notFoundObject.not_found_subject.info.client_id}
                              </TableCell> : null}

                            {notFoundObject.not_found_subject.info.customer_subject_id ?
                              <TableCell style={{ padding: "0px" }}>
                                {notFoundObject.not_found_subject.info.customer_subject_id}
                              </TableCell> : null}

                            {notFoundObject.not_found_subject.info.delivery_id ?
                              <TableCell style={{ padding: "0px" }}>
                                {notFoundObject.not_found_subject.info.delivery_id}
                              </TableCell> : null}

                            {notFoundObject.not_found_subject.info.import_file_id ?
                              <TableCell style={{ padding: "0px" }}>
                                {notFoundObject.not_found_subject.info.import_file_id}
                              </TableCell> : null}

                            {notFoundObject.not_found_subject.info.user ?
                              <TableCell style={{ padding: "0px" }}>
                                {notFoundObject.not_found_subject.info.user}
                              </TableCell> : null}
                          </TableRow>
                        </Table>
                      </Grid>
                      <Grid item xs={1}>
                        <Box textAlign="center">
                          <IconButton
                            color="primary"
                            aria-label="aggiungi"
                            component="span"
                            onClick={() =>
                              setActiveMissingSubject(
                                notFoundObject.not_found_subject
                              )
                            }
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {notFoundObject.results.length > 0 && (
                    <>
                      <Box padding={1}>
                        <Typography variant="body2">
                          Soggetti simili nel sistema:
                        </Typography>
                      </Box>
                      <SimilSubjectsTable
                        data={notFoundObject.results}
                        assignSubject={(data: any) =>
                          assignSubject(data).then(() => {
                            setActualData((oldData) => {
                              return oldData.filter(
                                (notFoundObjectold) =>
                                  `${notFoundObjectold.not_found_subject.business_name},
                                  ${notFoundObjectold.not_found_subject.address} 
                                  ${notFoundObjectold.not_found_subject.locality}` !==
                                  `${notFoundObject.not_found_subject.business_name},
                                  ${notFoundObject.not_found_subject.address} 
                                  ${notFoundObject.not_found_subject.locality}`
                              );
                            });
                          })
                        }
                      />
                    </>
                  )}
                </Box>
              </Paper>
            </Box>
          );
        })}
      <TablePagination
        count={ids.length}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        component="span"
      />
      {activeMissingSubject && (
        <Dialog
          onClose={toggleUpdateModal}
          open={isOpenUpdateModal}
          fullWidth
          maxWidth="xl"
        >
          <CustomForm
            initialValues={{
              business_name: activeMissingSubject.business_name,
              postal_code: activeMissingSubject.postal_code,
              address: activeMissingSubject.address,
              locality: activeMissingSubject.locality,
              province: activeMissingSubject.province,
              type: ["destinatario"],
            }}
            onSubmit={saveSubject}
            render={({ handleSubmit, submitting, pristine, values }) => (
              <>
                <DialogContent>
                  <SubjectFormContent fieldOptions={options} />
                </DialogContent>
                <DialogActions>
                  <CancelButton
                    onClick={toggleUpdateModal}
                    disabled={submitting}
                  />
                  <FormSaveButton
                    pristine={pristine}
                    submitting={submitting}
                    handleSubmit={handleSubmit}
                  />
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      )}
    </>
  );
};
