import { AutocompleteInput, Button, ListProps, useNotify } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { client } from "../../dataProvider";
import { useMemo, useRef, useState } from "react";
import { Autocomplete } from "@material-ui/lab";


const useStyles = makeStyles({
  root: {
    "& input": {
      height: "30px",
    },
    "& th": {
      borderRight: "0px solid lightgray",
      backgroundColor: "#fddfdd",
      padding: "10px 0px",
      fontSize: "1.2em",
      textAlign: "center",
    },
    "& td": {
      borderRight: "0px solid lightgray",
      padding: "4px 0px",
    },
    "& tr": {

      '&:hover': {
        backgroundColor: '#d1dfd1 !important' // fifth bg color
      }
    },

  }
});


export const WorkPermitTotalsList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const refSerarchBar = useRef(null);
  const classes = useStyles();

  const [loadingEmplyees, setLoadingEmplyees] = useState(true);
  const [employeesList, setEmployeesList] = useState<any>([]);
  const [employee, setEmployee] = useState<any>();
  const [loadingData, setLoadingData] = useState(true);
  const notify = useNotify();

  const [updateStats, setUpdateStats] = useState<any>(1);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [months, setMonths] = useState<any>([]);
  const [tableHeight, setTableHeight] = useState<any>(150);

  const [data, setData] = useState<any>([]);



  function loadEmpoyessList() {
    setLoadingEmplyees(true)
    client(`employees/get_all_employees`, {
      method: "GET",
      data: {
      }
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let employeesTemp = []
          for (let e in response) {
            employeesTemp.push({
              id: response[e].id,
              name: response[e].name,
              surname: response[e].surname,
              label: response[e].id + ": " + response[e].name + " " + response[e].surname
            })
          }
          setEmployeesList(employeesTemp)
          setLoadingEmplyees(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoadingEmplyees(false)
      });
  }


  function reloadList() {

    setLoadingData(true)
    client(`./workpermits/get_totals/?start_date=${startDate}&end_date=${endDate}&employee=${employee ? employee : ""}`, {
      method: "GET",
    }, true)
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          setData(response)
          setLoadingData(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoadingData(false)
      });
  }

  useMemo(() => {
    loadEmpoyessList()
    reloadList()
  }, [])



  return (
    <>
      <div style={{
        display: (loadingData || loadingEmplyees) ? "block" : "none",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 999,
      }}>
        <Typography style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "2em",
        }}>
          Loading...
        </Typography>
      </div>



      <div onKeyDown={(e) => {
        if (e.key === "Enter") {
          reloadList()
        }
      }}>
        {employeesList &&
          <Grid container
            ref={refSerarchBar}
            style={{
              padding: "10px",
              marginRight: "30px",
              width: "100%"
            }}>
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}

              id="start_date"
              label="Da"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
            />
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}
              id="end_date"
              label="A"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              multiple
              id="months"
              options={[
                { id: 1, label: 'Gennaio' },
                { id: 2, label: 'Febbraio' },
                { id: 3, label: 'Marzo' },
                { id: 4, label: 'Aprile' },
                { id: 5, label: 'Maggio' },
                { id: 6, label: 'Giugno' },
                { id: 7, label: 'Luglio' },
                { id: 8, label: 'Agosto' },
                { id: 9, label: 'Settembre' },
                { id: 10, label: 'Ottobre' },
                { id: 11, label: 'Novembre' },
                { id: 12, label: 'Dicembre' },
              ]}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let tempMonths = []
                for (let e in newValue) {
                  tempMonths.push(newValue[e].id)
                }
                tempMonths = tempMonths.sort(function (a, b) { return a - b })
                setMonths(tempMonths)
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona mesi"
                />
              )}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              id="tags-outlined"
              options={employeesList}
              getOptionLabel={(option: any) => option.label}
              onChange={(_, v) => setEmployee(v?.id)}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona dipendente"
                />
              )}
            />
            <Button
              style={
                {
                  height: "55px",
                  width: "100px",
                  marginRight: "10px"
                }
              }
              onClick={() => {
                reloadList()
              }}
              label=""
              color="primary"
              variant="contained"
            >
              <Typography>Filtra</Typography>
            </Button>
          </Grid>
        }

        <div style={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          height: "calc(100vh - " + tableHeight + "px)",
          width: "100%",
        }}>
          <Table className={classes.root}>
            <TableHead style={{
              position: "sticky",
              top: 0,
              boxShadow: "1px 1px 1px grey",
              borderRight: "1px solid black",
              zIndex: 999,
            }}>
              <TableRow>
                <TableCell>Operatore</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Totale</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any) => {
                return (
                  <TableRow >
                    <TableCell>
                      <Typography>{row.employee_id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.work_permit_sub_type__work_permit_type__name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.total_time}</Typography>
                    </TableCell>

                  </TableRow>)
              }
              )
              }
            </TableBody>
          </Table>

        </div>
      </div >
    </>
  );
};