import { Card } from "@material-ui/core";
import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { PresenceQrForm } from "./PresenceQrForm";

export const PresenceQrEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <PresenceQrForm />
    </DefaultEdit>
  );
};
