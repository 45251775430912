import React from "react";
import { AutocompleteArrayInput, AutocompleteInput, DateTimeInput, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { Grid, Typography } from "@material-ui/core";

export const PresenceForm = (props: FormProps) => {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
    >
      <ReferenceInput
        {...initInput("employee", props.fieldOptions, props.canEdit)}
        reference="autocomplete/employee"
        source="employee"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>
      <Grid container style={{ width: "100%" }} >
        <Grid item xs={6} style={{
          backgroundColor: "rgba(137, 247, 159, 0.36)",
          borderRadius: "0px 50px 0px 50px",
          padding: "10px"
        }}>
          <Typography variant="h6">Dati Ingresso</Typography>
          <DateTimeInput
            {...initInput("date_in", props.fieldOptions, props.canEdit)}
          />
          <TextInput
            {...initInput("qr_in", props.fieldOptions, props.canEdit)}
          />
          <TextInput
            {...initInput("gps_in", props.fieldOptions, props.canEdit)}
          />
          <TextInput
            multiline
            {...initInput("notes_in", props.fieldOptions, props.canEdit)}
            label="Note entrata"
          />
        </Grid>
        <Grid item xs={6} style={{
          backgroundColor: "rgba(247, 137, 137, 0.36)",
          borderRadius: "0px 50px 0px 50px",
          padding: "10px"
        }}>
          <Typography variant="h6">Dati uscita</Typography>
          <DateTimeInput
            {...initInput("date_out", props.fieldOptions, props.canEdit)}
          />
          <TextInput
            {...initInput("qr_out", props.fieldOptions, props.canEdit)}
          />
          <TextInput
            {...initInput("gps_out", props.fieldOptions, props.canEdit)}
          />
          <TextInput
            multiline
            {...initInput("notes_out", props.fieldOptions, props.canEdit)}
            label="Note uscita"
          />
        </Grid>
      </Grid>

      <TextInput
        multiline
        {...initInput("info_complete", props.fieldOptions, false)}
        label="Info"
      />
      <TextInput
        multiline
        {...initInput("description", props.fieldOptions, props.canEdit)}
        label="Note"
      />

    </SimpleForm >
  );
};
