import { Identifier } from "react-admin";
//import UTIF, { IFD } from "utif";
import { UTIF } from "./UTIF_MOD";
import { url } from "../../constants";
import { clientNoJson } from "../../dataProvider";
import { getFileNameFromContentDisposition } from "../../shared/utils";

export const downloadNote = async (noteId: Identifier, resource?: string, applyQr = true) => {
  if (!resource) {
    resource = "notes"
  }
  let res = null;
  try {
    res = await clientNoJson(`${url}/${`${resource}/${noteId}/download/`}${applyQr ? "" : "?apply_qr=false"}`, {
      responseType: "arraybuffer",
      method: "GET",
    });
  } catch (err) {
    return;
  }
  return res;
};

export const saveNoteToFile = async (noteId: Identifier, resource?: string) => {
  const res = await downloadNote(noteId, resource);
  if (res) {
    const data = res.data;
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  }
};
export const getNoteImage = async (noteId: Identifier, resource?: string) => {
  const res = await downloadNote(noteId, resource, false);
  if (res) {
    const data = res.data;
    const ifds = UTIF.decode(data);
    UTIF.decodeImage(data, ifds[0], ifds);
    const rgba = UTIF.toRGBA8(ifds[0]); // Uint8Array with RGBA pixels

    const imgd = new ImageData(
      new Uint8ClampedArray(rgba.buffer),
      ifds[0].width,
      ifds[0].height
    );
    const cnv = document.createElement("canvas");
    cnv.width = ifds[0].width;
    cnv.height = ifds[0].height;
    const ctx = cnv.getContext("2d");

    ctx!.putImageData(imgd, 0, 0);
    return cnv.toDataURL();
  }
};
